import React from "react";
import {
  Typography,
  Card,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

function Results({ data, theme }) {
  if (!data?.length > 0) {
    return null;
  }

  return (
    <Card>
      <Typography variant="h6" mb={2}>
        Risultati {data.length}
      </Typography>
      <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
        <Table
          stickyHeader
          aria-label="simple table"
          sx={{ minWidth: 500 }} // Set min width for the table
        >
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: "8px",
                  width: "120px", // Fixed width for the first column
                }}
              >
                Numero riga
              </TableCell>
              <TableCell sx={{ padding: "8px" }} align="left">
                Valore
              </TableCell>
              <TableCell sx={{ padding: "8px" }} align="left">
                Stato
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ padding: "8px", width: "100px" }} // Fixed width for the first column
                  >
                    {row.row}
                  </TableCell>
                  <TableCell align="left" sx={{ padding: "8px" }}>
                    {row.targa}
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      padding: "8px",
                      color: row.error
                        ? theme.palette.error.main
                        : theme.palette.success.main,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {row.error ? (
                      <>
                        <ErrorIcon
                          sx={{ marginRight: "8px" }}
                          style={{ color: theme.palette.error.main }}
                        />
                        {row.message}
                      </>
                    ) : (
                      <>
                        <CheckCircleIcon
                          sx={{ marginRight: "8px" }}
                          style={{ color: theme.palette.success.main }}
                        />
                        {row.message}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}

export default Results;
