import React from "react";
import { Box, Typography, IconButton, Fade } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import CustomSelect from "../../../components/CustomSelect";

const AltreInfo = ({
  data,
  otherInfoData,
  isAltreInfoVisible,
  toggleAltreInfoVisibility,
  maxWidth,
  handleChange,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: isAltreInfoVisible ? "16px" : "0",
        }}
      >
        <Typography variant="h6">Altre Info</Typography>
        <IconButton onClick={toggleAltreInfoVisibility}>
          <ExpandMore
            sx={{
              transform: isAltreInfoVisible ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          />
        </IconButton>
      </Box>
      {isAltreInfoVisible && (
        <Fade in={isAltreInfoVisible}>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: maxWidth === "xs" ? "column" : "row",
              }}
            >
              <CustomSelect
                name="titoloStudio"
                label={"Titolo Studio"}
                options={otherInfoData.titoloStudio.dataset}
                size="small"
                value={data.titoloStudio}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="professione"
                label={"Professione"}
                options={otherInfoData.professione.dataset}
                size="small"
                value={data.professione}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="statoCivile"
                label={"Stato Civile"}
                options={otherInfoData.statoCivile.dataset}
                size="small"
                value={data.statoCivile}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="tipoGuida"
                label={"Guida"}
                options={otherInfoData.tipoGuida.dataset}
                size="small"
                value={data.tipoGuida}
                onChange={handleChange}
                sx={{
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: maxWidth === "xs" ? "column" : "row",
                marginTop: maxWidth === "xs" ? "0" : "16px",
              }}
            >
              <CustomSelect
                name="antifurto"
                label={"Antifurto"}
                options={otherInfoData.antifurto.dataset}
                size="small"
                value={data.antifurto}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="ricovero"
                label={"Ricovero"}
                options={otherInfoData.ricovero.dataset}
                size="small"
                value={data.ricovero}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="usoVeicolo"
                label={"Uso Veicolo"}
                options={otherInfoData.usoVeicolo.dataset}
                size="small"
                value={data.usoVeicolo}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
              <CustomSelect
                name="figli"
                label={"Figli"}
                options={otherInfoData.figli.dataset}
                size="small"
                value={data.figli}
                onChange={handleChange}
                sx={{
                  marginRight: maxWidth === "xs" ? "0" : "16px",
                  maxWidth: maxWidth === "xs" ? "none" : "202px",
                  marginBottom: maxWidth === "xs" ? "16px" : "0",
                }}
              />
            </Box>
          </Box>
        </Fade>
      )}
    </>
  );
};

export default AltreInfo;
