import { createSlice } from '@reduxjs/toolkit';

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    messages: [],
    status: 'disconnected',
    error: null,
  },
  reducers: {
    websocketConnected: (state) => {
      state.status = 'connected';
      state.error = null;
    },
    websocketDisconnected: (state) => {
      state.status = 'disconnected';
    },
    websocketError: (state, action) => {
      state.status = 'error';
      state.error = action.payload;
    },
    websocketMessageReceived: (state, action) => {
      state.messages.push(action.payload);
    },
  },
});

export const {
  websocketConnected,
  websocketDisconnected,
  websocketError,
  websocketMessageReceived,
} = websocketSlice.actions;

export default websocketSlice.reducer;
