import React from 'react';
import DynamicForm from './DynamicForm';
import DynamicTable from './DynamicTable';
import { Accordion, AccordionDetails, AccordionSummary, Box, CardContent, Divider, Typography, useMediaQuery } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ComplexTable from './ComplexTable';
import { Card } from '../style';
import ListComponent from './ListComponent';

const DynamicComponent = ({
    nome,   
    rcDetails,
    less    
}) => {
    const isMobile = useMediaQuery("(max-width:600px)");

    const tab = rcDetails?.tabs?.find(el => el[nome])?.[nome];
    const generalType = tab.componentType
    const arrayData = Object.entries(tab); 
    const sorted = arrayData.sort((a, b) => a[1].position - b[1].position);
    const final = less ? sorted.filter(el => el[0] !== less) : sorted;


    const generateComponent = el => {
        console.log(el)
        const { componentType } = el[1];
        const hasSection = Boolean(el[1].section);

        if (componentType === "form" && !generalType) {
            const name = hasSection ? Object.keys(el[1].section)[0] : "";
            const insideSectionObj = hasSection ? el[1].section[name] : null;

            return (
                <Accordion key={el[0]} defaultExpanded={!isMobile && (el[0] === "Cliente" || el[0] === "Veicolo")}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h5">{el[0]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DynamicForm
                            parentName={nome}
                            isAccordion={true}
                            name={el[0]}
                            dataset={el[1].dataset}
                            sectName={name}
                            section={insideSectionObj}
                        />
                    </AccordionDetails>
                </Accordion>
            );
        }if (componentType === "table" && !generalType) {
            const name = hasSection ? Object.keys(el[1].section)[0] : "";
            const insideSectionObj = hasSection ? el[1].section[name] : null;

            return (
                <Accordion key={el[0]} defaultExpanded={!isMobile && (el[0] === "Attestato")}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h5">{el[0]}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box my={6}>
                            {Boolean(el[1].dynamic) ? (
                                <DynamicTable
                                    name={el[0]}
                                    dataset={el[1].dataset}
                                    section={insideSectionObj}
                                    sectName={name}
                                />
                            ) : (
                                <DynamicTable
                                    name={el[0]}
                                    dataset={el[1].dataset}
                                />
                            )}
                        </Box>
                        <Divider my={6} />
                        {hasSection && (
                            <>
                                <Typography variant="h6" mt={6}>{name}</Typography>
                                <Box my={6}>
                                    <ComplexTable
                                        name={el[0]}
                                        dataset={el[1].dataset}
                                        section={insideSectionObj}
                                        sectName={name}
                                    />
                                </Box>
                            </>
                        )}
                    </AccordionDetails>
                </Accordion>
            );
        } if (generalType === "list") {
            return (
                <Card key={el[0]}>
                        <ListComponent dataset={el[0] === "dataset" && el[1]} />
                </Card>
            );
        }
    };

    return (
        <CardContent>
            {final.map(el => generateComponent(el))}
        </CardContent>
    );
};

export default DynamicComponent;
