import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MyLogoLight from "../../vendor/ME_Light.png";
import SignInComponent from "../../components/auth/SignIn";
import { useDispatch } from "react-redux";
import { setMenuData } from "../../redux/slices/sidebarMenu";

const Brand = styled.img`
  fill: ${(props) => props.theme.palette.primary.main};
  display: block;
  margin: 0 auto;
  width: 256px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMenuData([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isUserLoggedIn = sessionStorage.getItem("token") != null;
    if (isUserLoggedIn) {
      navigate("/private");
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <Wrapper>
        <Brand src={MyLogoLight} alt="My Logo" />
        <Helmet title="Sign In" />
        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Welcome back!
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
