import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { ArrowDown, ArrowUp } from "react-feather";
import { rowsRiclassificato } from "./rows";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Riclassificato = ({ report }) => {
  const firstTableRows = rowsRiclassificato[0];
  const secondTableRows = rowsRiclassificato[1];
  const thirdTableRows = rowsRiclassificato[2];
  const fourthTableRows = rowsRiclassificato[3];

  const theme = useTheme();

  function filteredContoEconomico() {
    return report.localFinancialStatements?.filter(
      (statement) => statement.type === "LocalFinancialsCSIT"
    );
  }
  // Funzione per formattare i numeri
  const formatNumber = (num) =>
    parseFloat(num?.toString().replace(/,/g, "") || 0);

  // Funzione per calcolare la differenza percentuale
  function calculatePercentageDifference(currentValue, previousValue, inverso) {
    if (currentValue == null || previousValue == null || previousValue === 0)
      return "";

    const current = formatNumber(currentValue);
    const previous = formatNumber(previousValue);

    const percentageDifference = Math.abs(
      Math.ceil(((current - previous) / previous) * 100)
    );

    const color =
      inverso === "si"
        ? current > previous
          ? "#ee2e24"
          : "#4dbb81"
        : current > previous
        ? "#4dbb81"
        : "#ee2e24";
    const Icon = current > previous ? ArrowUp : ArrowDown;

    return (
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
      >
        <Icon size={10} />
        <Typography>{percentageDifference}%</Typography>
      </Box>
    );
  }

  function calculateBalance(num) {
    const color = num > 0 ? "#4dbb81" : "#ee2e24";
    const Icon = num > 0 ? ArrowUp : ArrowDown;
    const perc = `${Math.ceil(Math.abs(num))}%`;

    return (
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
      >
        <Icon size={10} />
        <Typography>{perc}</Typography>
      </Box>
    );
  }

  // Funzione per visualizzare una cella di tabella generica
  function renderTableCell(statement, key) {
    const value = statement
      ?.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");

    return (
      <Box textAlign="center" display="flex" justifyContent="center" gap={2}>
        {value ? value : 0}
      </Box>
    );
  }

  // Funzione per generare le righe
  function generateTableRow(row, key, numTable) {
    return (
      <TableRow key={key}>
        <TableCell width="20%">
          {key === "debtorsDueWithin1Year" ||
          key === "dueFromSuppliersWithin1Year" ||
          key === "dueWithin1Year" ||
          key === "dueToSuppliersWithin1Year" ||
          key === "dueToBankWithin1Year" ||
          key === "dueAfter1Year" ||
          key === "dueToSuppliersAfter1Year" ||
          key === "dueToBankAfter1Year" ? (
            <Typography textAlign="right">{row[key]}</Typography>
          ) : (
            <Typography>{row[key]}</Typography>
          )}
        </TableCell>
        {filteredContoEconomico().map((statement, i) => {
          switch (numTable) {
            case 1: {
              if (
                key === "operatingRevenues" ||
                key === "totalValueOfProduction"
              ) {
                const valueNum = statement.profitAndLoss?.[key];
                const num =
                  key === "operatingRevenues"
                    ? statement.ratios.changeInRevenuesPercentage
                    : statement.ratios.changeInTotalValueOfProductionPercentage;
                return (
                  <TableCell width="25%" key={i}>
                    <Box
                      textAlign="center"
                      display="flex"
                      justifyContent="center"
                      gap={2}
                    >
                      {renderTableCell(valueNum, key)}
                      {calculateBalance(num)}
                    </Box>
                  </TableCell>
                );
              }
              const valueNum = statement.profitAndLoss?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.profitAndLoss?.[key];

              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 2: {
              const valueNum = statement.balanceSheet?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.balanceSheet?.[key];

              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 3: {
              const valueNum = statement.balanceSheet?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.balanceSheet?.[key];

              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {key === "totalPayables" ||
                    key === "dueWithin1Year" ||
                    key === "dueToSuppliersWithin1Year" ||
                    key === "dueToBankWithin1Year" ||
                    key === "dueAfter1Year" ||
                    key === "dueToSuppliersAfter1Year" ||
                    key === "dueToBankAfter1Year" ||
                    key === "otherPayables" ||
                    key === "accruedExpensesAndPrepayments" ||
                    key === "totalLiabilities"
                      ? calculatePercentageDifference(
                          valueNum,
                          valueNumNext,
                          "si"
                        )
                      : calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 4: {
              const valueNum = statement.ratios?.[key];

              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                  </Box>
                </TableCell>
              );
            }
            default:
              break;
          }
        })}
      </TableRow>
    );
  }

  return (
    <Grid container spacing={6} mt={0.5}>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Conto Economico</Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(firstTableRows, "operatingRevenues", 1)}
                  {generateTableRow(
                    firstTableRows,
                    "changeInWorkInProgress",
                    1
                  )}
                  {generateTableRow(
                    firstTableRows,
                    "totalOtherIncomeAndRevenues",
                    1
                  )}
                  {generateTableRow(
                    firstTableRows,
                    "totalValueOfProduction",
                    1
                  )}
                  {generateTableRow(firstTableRows, "totalCostOfProduction", 1)}
                  {generateTableRow(firstTableRows, "purchaseOfGoods", 1)}
                  {generateTableRow(firstTableRows, "costOfServices", 1)}
                  {generateTableRow(firstTableRows, "useOfThirdPartyAssets", 1)}
                  {generateTableRow(
                    firstTableRows,
                    "totalPayrollAndRelatedCosts",
                    1
                  )}
                  {generateTableRow(
                    firstTableRows,
                    "otherOperatingExpenses",
                    1
                  )}
                  {generateTableRow(firstTableRows, "ebitda", 1)}
                  {generateTableRow(
                    firstTableRows,
                    "totalAmortisationDepreciationAndWriteDowns",
                    1
                  )}
                  {generateTableRow(firstTableRows, "ebit", 1)}
                  {generateTableRow(
                    firstTableRows,
                    "totalFinancialIncomeAndExpense",
                    1
                  )}
                  {generateTableRow(
                    firstTableRows,
                    "totalValueAdjustmentsToFinancialAssetsAndLiabilities",
                    1
                  )}
                  {generateTableRow(firstTableRows, "preTaxResult", 1)}
                  {generateTableRow(
                    firstTableRows,
                    "totalTaxesOnTheIncomeForTheYear",
                    1
                  )}
                  {generateTableRow(
                    firstTableRows,
                    "profitOrLossForTheYear",
                    1
                  )}
                  {generateTableRow(firstTableRows, "cashFlowPL", 1)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">
                Stato patrimoniale: Attivo
              </Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(
                    secondTableRows,
                    "receivablesDueFromShareholders",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "intangibleFixedAssets",
                    2
                  )}
                  {generateTableRow(secondTableRows, "tangibleFixedAssets", 2)}
                  {generateTableRow(secondTableRows, "financialFixedAssets", 2)}
                  {generateTableRow(secondTableRows, "totalFixedAssets", 2)}
                  {generateTableRow(secondTableRows, "totalInventories", 2)}
                  {generateTableRow(secondTableRows, "totalReceivables", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "debtorsDueWithin1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "dueFromSuppliersWithin1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "currentFinancialAssets",
                    2
                  )}
                  {generateTableRow(secondTableRows, "liquidAssets", 2)}
                  {generateTableRow(secondTableRows, "totalCurrentAssets", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "accruedIncomeAndPrepayments",
                    2
                  )}
                  {generateTableRow(secondTableRows, "totalAssets", 2)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">
                Stato patrimoniale: Passivo
              </Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(thirdTableRows, "shareholdersEquity", 3)}
                  {generateTableRow(thirdTableRows, "shareCapital", 3)}
                  {generateTableRow(thirdTableRows, "otherReserves", 3)}
                  {generateTableRow(
                    thirdTableRows,
                    "netProfitOrLossForTheYear",
                    3
                  )}
                  {generateTableRow(
                    thirdTableRows,
                    "provisionForRisksAndCharges",
                    3
                  )}
                  {generateTableRow(
                    thirdTableRows,
                    "provisionForSeveranceIndemnity",
                    3
                  )}
                  {generateTableRow(thirdTableRows, "totalPayables", 3)}
                  {generateTableRow(thirdTableRows, "dueWithin1Year", 3)}
                  {generateTableRow(
                    thirdTableRows,
                    "dueToSuppliersWithin1Year",
                    3
                  )}
                  {generateTableRow(thirdTableRows, "dueToBankWithin1Year", 3)}
                  {generateTableRow(thirdTableRows, "dueAfter1Year", 3)}
                  {generateTableRow(
                    thirdTableRows,
                    "dueToSuppliersAfter1Year",
                    3
                  )}
                  {generateTableRow(thirdTableRows, "dueToBankAfter1Year", 3)}
                  {generateTableRow(thirdTableRows, "otherPayables", 3)}
                  {generateTableRow(
                    thirdTableRows,
                    "accruedExpensesAndPrepayments",
                    3
                  )}
                  {generateTableRow(thirdTableRows, "totalLiabilities", 3)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Indici</Typography>
            </Box>
          </CardContent>

          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {filteredContoEconomico().map((statement) => {
                    const yearEndDate = new Date(
                      statement.yearEndDate
                    ).toLocaleDateString();
                    return (
                      <TableCell
                        style={{ minWidth: "10rem" }}
                        align="center"
                        key={yearEndDate}
                      >
                        {yearEndDate}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{
                    m: 4,
                    borderBottom: `1px solid ${
                      theme.palette.mode === "dark"
                        ? "rgba(81, 81, 81, 1)"
                        : "rgba(224, 224, 224, 1)"
                    }`,
                  }}
                >
                  <TableCell>INDICI DI SVILUPPO</TableCell>
                </TableRow>
                {generateTableRow(
                  fourthTableRows,
                  "changeInRevenuesPercentage",
                  4
                )}
                {generateTableRow(
                  fourthTableRows,
                  "changeInTotalValueOfProductionPercentage",
                  4
                )}
                {generateTableRow(
                  fourthTableRows,
                  "changeInTotalAssetsPercentage",
                  4
                )}
                {generateTableRow(
                  fourthTableRows,
                  "changeInShareholdersEquityPercentage",
                  4
                )}
                <TableRow
                  sx={{
                    m: 4,
                    borderBottom: `1px solid ${
                      theme.palette.mode === "dark"
                        ? "rgba(81, 81, 81, 1)"
                        : "rgba(224, 224, 224, 1)"
                    }`,
                  }}
                >
                  <TableCell>INDICI DI REDDITIVITÀ</TableCell>
                </TableRow>
                {generateTableRow(
                  fourthTableRows,
                  "returnOnSalesPercentage",
                  4
                )}
                {generateTableRow(
                  fourthTableRows,
                  "returnOnInvestmentPercentage",
                  4
                )}
                {generateTableRow(
                  fourthTableRows,
                  "returnOnEquityPercentage",
                  4
                )}
                <TableRow
                  sx={{
                    m: 4,
                    borderBottom: `1px solid ${
                      theme.palette.mode === "dark"
                        ? "rgba(81, 81, 81, 1)"
                        : "rgba(224, 224, 224, 1)"
                    }`,
                  }}
                >
                  <TableCell>INDICI DI LIQUIDITÀ</TableCell>
                </TableRow>
                {generateTableRow(fourthTableRows, "currentRatio", 4)}
                {generateTableRow(fourthTableRows, "acidTest", 4)}
                {generateTableRow(fourthTableRows, "debtorDays", 4)}
                {generateTableRow(fourthTableRows, "creditorDays", 4)}
                <TableRow
                  sx={{
                    m: 4,
                    borderBottom: `1px solid ${
                      theme.palette.mode === "dark"
                        ? "rgba(81, 81, 81, 1)"
                        : "rgba(224, 224, 224, 1)"
                    }`,
                  }}
                >
                  <TableCell>INDICI DI SOLVIBILITÀ</TableCell>
                </TableRow>
                {generateTableRow(fourthTableRows, "pfn", 4)}
              </TableBody>
            </Table>
          </TableWrapper>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Riclassificato;
