import { AccountBalance } from "@mui/icons-material";
import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";

export const columnsDatagrid = () => {
  const columns = [
    //Società, indirizzo un pò più piccolo. Icona da rcAuto società
    //Eseguita da, colonna con utente e dataCreazione
    {
      field: "Società",
      headerName: "Società",
      minWidth: 100,
      width: 350,
      renderCell: (params) => {
        const { RagioneSociale, indirizzo, idUID } = params.row;
        return (
          <React.Fragment>
            <AccountBalance />
            <Box marginLeft="8px">
              <Link href={`/pages/visuredetails/${idUID}`} fontWeight="bold">
                {RagioneSociale}
              </Link>
              <Typography fontSize="10px">{indirizzo}</Typography>
            </Box>
          </React.Fragment>
        );
      },
    },
    {
      field: "piva",
      headerName: "P.IVA",
      minWidth: 100,
      width: 125,
    },
    {
      field: "eseguitoDa",
      headerName: "Eseguito da",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const { utente, dataCreazione } = params.row;
        return (
          <Box>
            <Typography fontWeight="bold">{utente}</Typography>
            <Typography fontSize="10px"> {dataCreazione}</Typography>
          </Box>
        );
      },
    },
  ];

  const columnsModale = [
    {
      field: "id",
      headerName: "#",
      minWidth: 10,
      width: 25,
    },
    {
      field: "RagioneSociale",
      headerName: "Ragione Sociale",
      minWidth: 100,
      width: 400,
    },
    {
      field: "PIVA",
      headerName: "P.IVA",
      minWidth: 100,
      width: 125,
    },
    {
      field: "N. REA",
      headerName: "N. REA",
      minWidth: 100,
      width: 125,
    },
    {
      field: "indirizzo",
      headerName: "Indirizzo",
      minWidth: 100,
      width: 420,
    },
    {
      field: "stato",
      headerName: "Stato",
      minWidth: 100,
      width: 125,
    },
  ];

  return columnsModale, columns;
};
