import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Line } from "react-chartjs-2";
import { rgba } from "polished";

import {
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Card as MuiCard,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import { blue, green, orange, red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { MoreVertical } from "react-feather";
import { showSnackbar } from "../../../../redux/slices/snackbar";
import { useDispatch } from "react-redux";
import { generalErrorMessage } from "../../rcAuto/constant";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 269px;
  position: relative;
`;

function LineChart({ lineChartData }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const [scope, setScope] = useState(lineChartData.Preventivi);
  const [isSelected, setIsSelected] = useState("Preventivi");
  const dispatch = useDispatch();

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "OK",
        backgroundColor: "transparent",
        borderColor: green[700],
        tension: 0.4,
        data: [],
      },
      {
        label: "Errore",
        backgroundColor: "transparent",
        borderColor: rgba(red[500], 0.75),
        tension: 0.4,
        data: [],
      },
      {
        label: "Non Gestibili",
        fill: true,
        backgroundColor: "transparent",
        borderColor: rgba(orange[500], 0.75),
        tension: 0.4,
        data: [],
      },
      {
        label: "Pending",
        fill: true,
        backgroundColor: "transparent",
        borderColor: rgba(blue[400], 0.75),
        tension: 0.4,
        data: [],
      },
    ],
  });

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  useEffect(() => {
    if (scope) {
      const labels = scope.map((item) => item.Ora);
      const OK = scope.map((item) => item.ok);

      const Errore = scope.map((item) => item.errore);

      const NonGestibile = scope.map((item) => item.nonGestibili);

      const Pending = scope.map((item) => item.pending);

      setData({
        labels,
        datasets: [
          {
            ...data.datasets[0],
            data: OK,
          },
          {
            ...data.datasets[1],
            data: Errore,
          },

          {
            ...data.datasets[2],
            data: NonGestibile,
          },
          {
            ...data.datasets[3],
            data: Pending,
          },
        ],
      });
    } else {
      /* navigate("/private"); */
      handleSnackbar("Errore: cambiare scope", "error");
      //snakbar
    }
  }, [scope, theme.palette.secondary.main]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const handleClickPuntini = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (scope) => {
    setAnchorEl(null);
    switch (scope) {
      case "Preventivi":
        setScope(lineChartData.Preventivi);
        setIsSelected("Preventivi");
        break;
      case "Ania":
        setScope(lineChartData.Ania);
        setIsSelected("Ania");
        break;
      case "Asset":
        setScope(lineChartData.Asset);
        setIsSelected("Asset");
        break;
      default:
        break;
    }
  };

  return (
    <Card mb={1}>
      <CardHeader
        action={
          <>
            <IconButton
              aria-label="settings"
              size="large"
              onClick={handleClickPuntini}
              aria-owns={anchorEl ? "scope" : undefined}
            >
              <MoreVertical />
            </IconButton>
            <Menu
              id="scope"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              color="secondary"
              onClose={handleClick}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{
                "& .MuiMenu-paper": {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
                },
              }}
            >
              <MenuItem onClick={() => handleClick("Preventivi")}>
                Preventivi
              </MenuItem>
              <MenuItem onClick={() => handleClick("Ania")}>Ania</MenuItem>
              <MenuItem onClick={() => handleClick("Asset")}>Asset</MenuItem>
            </Menu>
          </>
        }
        title={`Trend: ${isSelected}`}
      />
      <CardContent>
        <ChartWrapper>
          <Line data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}

export default withTheme(LineChart);
