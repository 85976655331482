import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Divider as MuiDivider,
  Typography, CircularProgress
} from "@mui/material";
import Section from "../../dynamic/Section";
import { Helmet } from "react-helmet-async";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

const Divider = styled(MuiDivider)(spacing);

function Testvinz() {
  const [pageConfig, setPageConfig] = useState({});
  const navigate = useNavigate();
  const [loadingPage, setLoadingPage] = useState(true);

  async function apiTestvinz(rowsPages, pageNumbers, nome) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          data: {
            action: "lmGetPage",
            page: pageNumbers,
            pageSize: rowsPages,
            filter: nome,
          },
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/getData",
        requestOptions
      );

      const responseData = await response.json();
      console.log("Risposta API:", responseData); // Per verificare la risposta

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }
      setPageConfig(responseData.data);
    } catch (error) {
      console.error("Errore nella chiamata API:", error);
      return error;
    }
  }

  useEffect(() => {
    apiTestvinz(5, 1, "").catch((err) => {
      console.log(err);
    }).finally(() => setLoadingPage(false));
  }, []);

  //if (!pageConfig || !pageConfig.sections) return <Typography>Caricamento...</Typography>;

  return (
    <React.Fragment>
      <Helmet title={pageConfig.pageTitle} />
      {loadingPage ? (
        <Box sx={{width: "calc(100vw - 40px)", display: "flex",justifyContent: "center",}}><CircularProgress /></Box>
    ):(      
      <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          {pageConfig.pageTitle}
        </Typography>
      </Box>
      <Divider sx={{ my: 4 }} />
      
    {pageConfig.sections.map((section, index) => (
        <Section key={index} section={section} />
      ))}
      </React.Fragment>
    )}
      
    </React.Fragment>
  );
}

export default Testvinz;
