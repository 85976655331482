import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import LineChart from "./charts/LineChart";
import TabellaRiepilogo from "./charts/TabellaRiepilogo";

const Charts = ({ graph, dataTabella }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      container
      sx={{
        margin: isDesktop ? "0 -16px" : "0",
        padding: "0",
        width: isDesktop ? "calc(100% + 16px)" : "100%",
      }}
      spacing={isDesktop ? 4 : 0}
    >
      <Grid item lg={4} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
        <TabellaRiepilogo data={graph} dataModale={dataTabella} />
      </Grid>
      <Grid item lg={8} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
        <LineChart lineChartData={graph} data={dataTabella} />
      </Grid>
    </Grid>
  );
};

export default Charts;
