import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { ChipBlue, ChipGreen, ChipOrange, ChipRed, ChipYellow } from "../style";

import Modale from "../modale/Modale";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const TabellaRiepilogo = ({ theme, data, dataModale }) => {
  const [preventivoOK, setPreventivoOK] = useState();
  const [preventivoErrore, setPreventivoErrore] = useState();
  const [preventivoNonGestibili, setPreventivoNonGestibili] = useState();
  const [preventivoPending, setPreventivoPending] = useState();
  const [aniaOK, setAniaOK] = useState();
  const [aniaErrore, setAniaErrore] = useState();
  const [aniaNonGestibili, setAniaNonGestibili] = useState();
  const [aniaPending, setAniaPending] = useState();
  const [assetOK, setAssetOK] = useState();
  const [assetErrore, setAssetErrore] = useState();
  const [assetNonGestibili, setAssetNonGestibili] = useState();
  const [assetPending, setAssetPending] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stato, setStato] = useState("");
  const [codice, setCodiceStato] = useState();
  const [scope, setScope] = useState("");
  const [codiceScope, setCodiceScope] = useState();

  const navigate = useNavigate();

  const handleModal = (stato, codiceStato, scope, codiceScope) => {
    setIsModalOpen(true);
    setStato(stato);
    setCodiceStato(codiceStato);
    setScope(scope);
    setCodiceScope(codiceScope);
  };

  useEffect(() => {
    if (data) {
      if (data.Preventivi) {
        // Preventivi OK
        const preventiviOK = data.Preventivi.map((prev) => prev.ok);
        const totalPreventiviOK = preventiviOK?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPreventivoOK(totalPreventiviOK);
        // Preventivi Errore
        const preventiviErrore = data.Preventivi.map((prev) => prev.errore);
        const totalPreventiviErrore = preventiviErrore?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPreventivoErrore(totalPreventiviErrore);
        // Preventivi Non Gestibili
        const preventiviNonGestibili = data.Preventivi.map(
          (prev) => prev.nonGestibili
        );
        const totalPreventiviNonGestibili = preventiviNonGestibili?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPreventivoNonGestibili(totalPreventiviNonGestibili);
        // Preventivi Pending
        const preventiviPending = data.Preventivi.map((prev) => prev.pending);
        const totalPreventiviPending = preventiviPending?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setPreventivoPending(totalPreventiviPending);
      }
      if (data.Ania) {
        //Ania OK
        const aniaOK = data.Ania.map((ania) => ania.ok);
        const totalAniaOK = aniaOK?.reduce((acc, curr) => acc + curr, 0);
        setAniaOK(totalAniaOK);
        //Ania Errore
        const aniaErrore = data.Ania.map((ania) => ania.errore);
        const totalAniaErrore = aniaErrore?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAniaErrore(totalAniaErrore);
        //Ania Non Gestibile
        const aniaNonGestibili = data.Ania.map((ania) => ania.nonGestibili);
        const totalAniaNonGestibili = aniaNonGestibili?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAniaNonGestibili(totalAniaNonGestibili);
        //Ania Pending
        const aniaPending = data.Ania.map((ania) => ania.pending);
        const totalAniapending = aniaPending?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAniaPending(totalAniapending);
      }
      if (data.Asset) {
        //Asset OK
        const assetOK = data.Asset.map((asset) => asset.ok);
        const totalAssetOK = assetOK?.reduce((acc, curr) => acc + curr, 0);
        setAssetOK(totalAssetOK);
        //Asset Errore
        const assetErrore = data.Asset.map((asset) => asset.errore);
        const totalAssetErrore = assetErrore?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAssetErrore(totalAssetErrore);
        //Asset Non Gestibile
        const assetNonGestibili = data.Asset.map((asset) => asset.nonGestibili);
        const totalAssetNonGestibili = assetNonGestibili?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAssetNonGestibili(totalAssetNonGestibili);
        //Asset Pending
        const assetPending = data.Asset.map((asset) => asset.pending);
        const totalAssetPending = assetPending?.reduce(
          (acc, curr) => acc + curr,
          0
        );
        setAssetPending(totalAssetPending);
      }
    } else {
      navigate("/private");
    }
  }, [data, theme.palette.background.paper]);

  return (
    <Card mb={6}>
      <CardHeader title="Riepilogo" />

      <CardContent>
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Stato</TableCell>
                <TableCell align="center">Prev.</TableCell>
                <TableCell align="center">Ania</TableCell>
                <TableCell align="center">Asset</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <ChipGreen label="OK" sx={{ width: "96px" }} />
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight="bold">{preventivoOK}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight="bold">{aniaOK}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography fontWeight="bold">{assetOK}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <ChipRed label="Errore" sx={{ width: "96px" }} />
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Errore", 4, "Preventivi", 0)}
                  >
                    {preventivoErrore}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Errore", 4, "ania", 2)}
                  >
                    {aniaErrore}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Errore", 4, "Asset", 1)}
                  >
                    {assetErrore}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <ChipOrange label="Non Gestibili" sx={{ width: "96px" }} />
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() =>
                      handleModal("Non Gestibile", 5, "Preventivi", 0)
                    }
                  >
                    {preventivoNonGestibili}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Non Gestibile", 5, "ania", 2)}
                  >
                    {aniaNonGestibili}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Non Gestibile", 5, "Asset", 1)}
                  >
                    {assetNonGestibili}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <ChipBlue label="Pending" sx={{ width: "96px" }} />
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Pending", 2, "Preventivi", 0)}
                  >
                    {preventivoPending > 0 ? (
                      <ChipYellow label={preventivoPending} />
                    ) : (
                      preventivoPending
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Pending", 2, "ania", 2)}
                  >
                    {aniaPending > 0 ? (
                      <ChipYellow label={aniaPending} />
                    ) : (
                      aniaPending
                    )}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        textDecoration: "underline",
                      },
                    }}
                    color="primary"
                    fontWeight="bold"
                    onClick={() => handleModal("Pending", 2, "Asset", 1)}
                  >
                    {assetPending > 0 ? (
                      <ChipYellow label={assetPending} />
                    ) : (
                      assetPending
                    )}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Modale
            open={isModalOpen}
            setOpen={setIsModalOpen}
            dati={dataModale}
            statoProp={stato}
            codiceStatoProp={codice}
            openFrom="TabellaRiepilogo"
            scopeProp={scope}
            codiceScopeProp={codiceScope}
          />
        </TableWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(TabellaRiepilogo);
