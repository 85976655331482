import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Divider,
  Popover as MuiPopover,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Lock, Phone, Mail } from "react-feather";
import { useNavigate } from "react-router-dom";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 350px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const ContactBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const SectionTitle = styled(Typography)`
  margin-top: 8px;
  font-weight: bold;
`;

function PopoverUser({
  data,
  canViewUserData,
  utenteIDUID,
  isUserPopupOpen,
  handleCloseUserPopup,
  userRef,
}) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/pages/profile/${utenteIDUID}`);
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      anchorEl={userRef}
      onClose={handleCloseUserPopup}
      open={isUserPopupOpen}
    >
      {canViewUserData ? (
        <ContentBox>
          <FlexBox>
            <Typography variant="h6">{data.generale.Nominativo}</Typography>
            <Typography variant="body2" color="textSecondary">
              {data.generale.Indirizzo}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {data.generale.Comune}
            </Typography>

            <Divider sx={{ marginY: 1 }} />

            <SectionTitle variant="subtitle1">RUI</SectionTitle>
            <Typography variant="body2">
              <strong>Sezione:</strong> {data.RUI.sezione}
            </Typography>
            <Typography variant="body2">
              <strong>Codice:</strong> {data.RUI.codice}
            </Typography>
            <Typography variant="body2">
              <strong>Data:</strong> {data.RUI.data}
            </Typography>

            <Divider sx={{ marginY: 1 }} />

            <SectionTitle variant="subtitle1">Contatti</SectionTitle>
            {data.contatti.map((contatto, index) => (
              <ContactBox key={index}>
                <SvgIcon fontSize="small">
                  {contatto.Tipologia === "Cellulare" ? <Phone /> : <Mail />}
                </SvgIcon>
                <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                  {contatto.Valore}
                </Typography>
              </ContactBox>
            ))}
          </FlexBox>

          <ButtonBox>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigation}
            >
              Visualizza tutto
            </Button>
          </ButtonBox>
        </ContentBox>
      ) : (
        <CenteredBox>
          <SvgIcon fontSize="medium">
            <Lock />
          </SvgIcon>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", marginTop: "8px" }}
          >
            Lei non ha accesso a i dati di questo utente
          </Typography>
        </CenteredBox>
      )}
    </Popover>
  );
}

export default PopoverUser;
