import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
  Paper as MuiPaper,
} from "@mui/material";
import { spacing } from "@mui/system";
import { FormControl as MuiFormControl } from "@mui/material";

export const Paper = styled(MuiPaper)(spacing);

export const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

export const Button = styled(MuiButton)(spacing);

export const Card = styled(MuiCard)(spacing);

export const Chip = styled(MuiChip)(spacing);

export const Divider = styled(MuiDivider)(spacing);

export const Grid = styled(MuiGrid)(spacing);

export const LinearProgress = styled(MuiLinearProgress)(spacing);

export const Spacer = styled.div(spacing);

export const Typography = styled(MuiTypography)(spacing);

export const Centered = styled.div`
  text-align: center;
`;

export const Avatar = styled(MuiAvatar)`
  display: flex;
  height: 128px;
  width: 128px;
`;

export const AboutIcon = styled.span`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(2)};

  svg {
    width: 14px;
    height: 14px;
  }
`;

export const ChartWrapper = styled.div`
  height: 280px;
  position: relative;
`;

export const StatsIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 32px;

  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export const TabsWrapper = styled.div`
  display: grid;
  flex-direction: column;
`;

export const TabContent = styled.div`
  display: grid;
  flex-direction: column;
  margin-top: ${(props) => props.theme.spacing(3)};
`;

const FormControlSpacing = styled(MuiFormControl)(spacing);

export const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
