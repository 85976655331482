import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import { Doughnut } from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { grey } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 240px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  text-align: center;
  z-index: 0;
`;

const DoughnutChart = ({
  theme,
  doughnutChartData,
  filterOptions,
  setFilterByDate,
}) => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [anchorMenu, setAnchorMenu] = useState(null);

  useEffect(() => {
    if (doughnutChartData) {
      const labels = doughnutChartData.map((item) => item.stato);
      const dataValues = doughnutChartData.map((item) => item.Tot);
      const total = dataValues.reduce((acc, curr) => acc + curr, 0);

      const backgroundColor = doughnutChartData.map((item) => {
        switch (item.stato) {
          case "Completato":
            return "#4caf50"; // Green
          case "In Corso":
            return "#f48b36"; // Orange
          case "Annullato":
          case "Errore":
          case "Non gestibile":
          case "Non disponibile":
            return "#f44336"; // Red
          case "In attesa dati":
          case "Verifica Accordi":
            return "#f48b36"; // Orange
          case "Da gestire":
            return "#3661f4"; // Blue
          default:
            return grey[300];
        }
      });

      setData({
        labels,
        datasets: [
          {
            data: dataValues,
            backgroundColor,
            borderWidth: 5,
            borderColor: theme.palette.background.paper,
          },
        ],
      });

      setTotalRecords(total);
    }
  }, [doughnutChartData, theme.palette.background.paper]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        display: false, // Hide the data values
      },
    },
    cutout: "80%",
  };

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleDateFilterChange = (value) => {
    setFilterByDate(value);
    closeMenu();
  };

  return (
    <Card mb={4}>
      <CardHeader
        action={
          <>
            <IconButton aria-label="settings" size="large" onClick={toggleMenu}>
              <MoreVertical />
            </IconButton>
            <Menu
              id="date-filter-menu"
              anchorEl={anchorMenu}
              open={Boolean(anchorMenu)}
              onClose={closeMenu}
            >
              {filterOptions
                ?.find((option) => option.label === "Aging")
                ?.dataset?.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleDateFilterChange(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
            </Menu>
          </>
        }
        title="Overview"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">{totalRecords}</Typography>
            <Typography variant="caption">Record Totali</Typography>
          </DoughnutInner>
          <Doughnut data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
