import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import sidebarMenuReducer from "./slices/sidebarMenu";
import roleReducer from "./slices/role";
import snackbarReducer from "./slices/snackbar";
import websocketReducer from "./slices/websocket";
import websocketMiddleware from "./middlewares/websocket";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    sidebarMenu: sidebarMenuReducer,
    role: roleReducer,
    websocket: websocketReducer,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(websocketMiddleware),
});
