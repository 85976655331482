import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Data = () => {
  const [pageNumbers, setPageNumbers] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [visureRows, setVisureRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isRemovingFilters, setIsRemovingFilters] = useState(false);
  const [isTableChanging, setIsTableChanging] = useState(false);
  const [barChartData, setBarChartData] = useState(null);
  const [canClearFilters, setCanClearFilters] = useState(false);
  const [clearTextField, setClearTextField] = useState(false);

  const navigate = useNavigate();

  async function visureGetList(rowsPages, pageNumbers, ragioneSociale, piva) {
    const token = sessionStorage.getItem("token");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          rowsPages: rowsPages,
          pageNumbers: pageNumbers,
          ragioneSociale: ragioneSociale,
          piva: piva,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/visureGetList",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }
      return responseData;
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    setIsTableChanging(true);
    visureGetList(rowsPerPage, pageNumbers, "", "")
      .then((response) => {
        if (response.status === 1) {
          setTotalRows(response?.data?.totalRows);
          setVisureRows(response?.data?.dataset);
          setBarChartData(response?.data?.graphBarData);
          setIsTableChanging(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    /* .finally(() => {
            setLoading(false);
          }); */
  }, [pageNumbers, rowsPerPage]);

  const handlePageChange = (newPage) => {
    if (isTableChanging) {
      return;
    } else {
      setPageNumbers(newPage + 1);
    }
  };

  // function that changes the table rows (size)
  const handlePageSizeChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPageNumbers(1);
  };

  const handleApplyFilters = (ragioneSociale, piva) => {
    setFiltersLoading(true);
    setIsTableChanging(true);
    setClearTextField(false);
    visureGetList(rowsPerPage, pageNumbers, ragioneSociale, piva)
      .then((response) => {
        if (response.status === 1) {
          setTotalRows(response?.data?.totalRows);
          setVisureRows(response?.data?.dataset);
          setBarChartData(response?.data?.graphBarData);
          setCanClearFilters(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFiltersLoading(false);
        setIsTableChanging(false);
      });
  };

  const handleRemoveFilters = () => {
    setIsRemovingFilters(true);
    setIsTableChanging(true);
    setClearTextField(true);
    visureGetList(rowsPerPage, pageNumbers, "", "")
      .then((response) => {
        if (response.status === 1) {
          setTotalRows(response?.data?.totalRows);
          setVisureRows(response?.data?.dataset);
          setBarChartData(response?.data?.graphBarData);
          setCanClearFilters(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsRemovingFilters(false);
        setIsTableChanging(false);
      });
  };

  return {
    visureRows,
    totalRows,
    pageNumbers,
    rowsPerPage,
    handleApplyFilters,
    handleRemoveFilters,
    filtersLoading,
    isRemovingFilters,
    handlePageChange,
    handlePageSizeChange,
    barChartData,
    canClearFilters,
    clearTextField,
  };
};
