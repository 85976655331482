// TabsComponent.js
import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import TabContent from "./TabContent";

function a11yProps(index) {
  return {
    id: `dynamic-tab-${index}`,
    "aria-controls": `dynamic-tabpanel-${index}`,
  };
}

function TabsComponent({ tabsConfig }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="dynamic tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ borderBottom: 1, borderColor: "divider" }}
      >
        {tabsConfig.map((tab, index) => (
          <Tab label={tab.name} key={index} {...a11yProps(index)} />
        ))}
      </Tabs>
      {tabsConfig.map((tab, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`dynamic-tabpanel-${index}`}
          aria-labelledby={`dynamic-tab-${index}`}
          key={index}
        >
          {value === index && (
            <Box sx={{ p: 3 }}>
              <TabContent content={tab.content} />
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
}

export default TabsComponent;
