import { useState, useEffect } from "react";
import * as XLSX from "xlsx";

export const useExcelParser = (file) => {
  const [excelData, setExcelData] = useState(null);
  const [error, setError] = useState(null);
  const [columnData, setColumnData] = useState(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const binaryStr = event.target.result;
          const workbook = XLSX.read(binaryStr, { type: "binary" });

          // Extract sheet names
          const sheetNames = workbook.SheetNames;

          // Get details of each sheet (rows, columns)
          const sheetData = sheetNames.map((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const range = XLSX.utils.decode_range(worksheet["!ref"]);
            return {
              name: sheetName,
              rows: range.e.r + 1, // row count
              columns: range.e.c + 1, // column count
              data: worksheet, // store the full worksheet data for later access
            };
          });

          setExcelData(sheetData);
        } catch (err) {
          setError("Failed to parse the Excel file");
        }
      };

      reader.onerror = () => {
        setError("Failed to read the file");
      };

      reader.readAsBinaryString(file);
    }
  }, [file]);

  const extractColumnData = (sheetIndex, columnIndex) => {
    if (excelData && excelData[sheetIndex]) {
      const sheet = excelData[sheetIndex].data;
      const columnData = [];

      // Loop through each row and get the value from the specified column
      for (let r = 0; r <= excelData[sheetIndex].rows; r++) {
        const cellAddress = XLSX.utils.encode_cell({ r, c: columnIndex });
        const cell = sheet[cellAddress];
        columnData.push(cell ? cell.v : null); // Push cell value or null if empty
      }
      setColumnData(columnData);
    }
  };

  return { excelData, columnData, extractColumnData, error };
};
