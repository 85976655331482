import {
	Link,
	FormControl,
	TextField,
	Button,
	Grid,
	Stack,
	Box,
	Avatar,
	Card,
	CardContent,
	Chip,
	Divider,
	Paper,
	Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { deepOrange } from "@mui/material/colors";
import { useEffect, useState } from 'react'
import { isString } from "formik";
import { useNavigate } from "react-router-dom";


const Agenzie = () => {
	const navigate = useNavigate()
	const [col, setCol] = useState([])
	const [row, setRow] = useState([])
	const [searchParam, setSearchParam] = useState("");
	const [filteredList, setFiltered] = useState([])

	useEffect(() => {
		const filteredList = row.filter((r) =>
			r.Azienda.toLowerCase().includes(searchParam.toLowerCase())
		);
		setFiltered(filteredList);
	}, [searchParam, row]);
	const handleSearch = (e) => {
		setSearchParam(e.target.value);
	};

  
	useEffect(() => {

		const getAgencies = async () => {
			const token = sessionStorage.getItem("token");

			const payload = {
				token: token,
				data: {
					action: "agenciesList"
				}
			};

			try {
				const res = await fetch('https://devops.mobilityexpress.it/api/getData', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload)
				});

				const data = await res.json()

				var tmp_col = [];
				var keys = Object.keys(data.data[0])

				const getWidth = (field) => {
					if (field === 'Indirizzo') {
						return 500
					}
					else if (field === 'Azienda') {
						return 300
					}
					return 200
				}
				for (var i = 0; i < keys.length; i++) {

					if (keys[i] === 'iduid' || keys[i] === 'PIVA') {
						continue;
					}

					tmp_col.push({
						field: keys[i],
						headerName: keys[i],
						width: getWidth(keys[i]),
						editable: true,
						renderCell: (params) => {

							if (params.field === 'stato') {
								return (
									<Box sx={{ display: "flex", justifyContent: "center", width: '100%' }}>
										<Chip
											label={params.value ? "Attivo" : "Non Attivo"}
											color={params.value ? "success" : "error"}
											size="small"
											sx={{ color: "white" }}
										/>
									</ Box>)
							}
							else if (isString(params.value) && params.value.includes('\\n')) {
								const val = params.value.split('\\n')
								return <Box sx={{ display: 'flex' }}>
									<Avatar
										sx={{
											bgcolor: deepOrange[500],
											fontWeight: "bold",
											fontSize: 50,
											margin: "auto",
										}}
									/>
									<Box sx={{ marginLeft: '30px' }}>
										<Typography sx={{ fontWeight: 'bold' }}> {val[0]} </Typography>  <Typography variant='caption'> {val[1]}
										</Typography>
									</ Box>
								</Box>
							}
							else if (params.field === 'Responsabile') {

								return (<Link
									onClick={() => {
										navigate(`/pages/profile/${params.row.iduid}`, {
											state: { guiIdSubUser: params.row.iduid, typeuser: "super" },
										});
									}}
									style={{ cursor: "pointer" }}
								>
									{params.value}
								</Link>)
							}
							else {
								return (
									<Typography>
										{params.value}
									</ Typography>
								)
							}
						}
					})
				}
				var tmp_row = []
				for (var i = 0; i < data.data.length; i++) {
					if (i === 'PIVA') {
						continue;
					}
					if (i === 'responsabile') {}
					const values = data.data[i]
					data.data[i]['id'] = i
					tmp_row.push(values)
				}
				setCol(tmp_col)
				setRow(tmp_row)

			}
			catch (err) {
				console.log(err)
			}
		}
		getAgencies()
	}, [])



	return (
		<>

			<Typography mb={6} variant="h3" gutterBottom display="inline">
				Agenzie
			</Typography>
			<Divider sx={{ my: 6 }} />

			<Card mb={6}>
				<CardContent sx={{ display: 'flex', justifyContent: 'space-between' }} pb={1}>
					<Typography variant="h6" gutterBottom>
						Risultati: {row.length}
					</Typography>

					<FormControl sx={{ maxHeight: "30px", maxWidth: "200px" }}>
						<TextField
							size="small"
							type="text"
							placeholder="Cerca agenzia"
							onChange={(e) => handleSearch(e)}
							sx={{
								marginRight: "2rem",
								maxHeight: "30px",
								"& .MuiInputBase-root": {
									height: "30px",
								},
								"& .MuiInputBase-input": {
									height: "100%",
									padding: "0 14px",
								},
							}}
						/>
					</FormControl>


				</CardContent>
				<Paper>
					<div style={{ height: 400, width: "100%" }}>
						<DataGrid
							rowsPerPageOptions={[5, 10, 25]}
							rows={searchParam ? filteredList : row}
							columns={col}
							pageSize={5}

						/>
					</div>
				</Paper>
			</Card>



		</>
	)
}

export default Agenzie;
