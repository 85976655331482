import React, { useState } from "react";
import {
  Box,
  Autocomplete,
  TextField,
  Grid,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ExpandMore } from "@mui/icons-material";

function AddressSection({
  value,
  options,
  searching,
  handleAddressChange,
  debouncedSetInputValue,
  fullAddress,
  toggelAddressVisibility,
  isAddressVisible,
}) {
  const [isEditingAddress, setIsEditingAddress] = useState(false);

  const handleEditAddress = () => {
    setIsEditingAddress(true);
  };

  const handleCancelModification = () => {
    fullAddress && setIsEditingAddress(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: isAddressVisible ? "16px" : "0",
          }}
        >
          <Typography variant="h6">Indirizzo</Typography>
          <IconButton onClick={toggelAddressVisibility}>
            <ExpandMore
              sx={{
                transform: isAddressVisible ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          </IconButton>
        </Box>
        {isAddressVisible && (
          <>
            {fullAddress && !isEditingAddress ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography variant="body" mr={4}>
                  {fullAddress}
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleEditAddress}
                >
                  Cambia
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Autocomplete
                  id="indirizzo-input"
                  sx={{ maxWidth: "415px", width: "100%" }}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description
                  }
                  filterOptions={(x) => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  value={value}
                  noOptionsText={
                    searching
                      ? "Cercando indirizzo..."
                      : "Nessun indirizzo trovato"
                  }
                  onChange={(event, newValue) => {
                    handleAddressChange(newValue);
                  }}
                  onInputChange={(event, newInputValue) => {
                    debouncedSetInputValue(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Indirizzo di residenza"
                      size="small"
                      fullWidth
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid item sx={{ display: "flex", width: 44 }}>
                          <LocationOnIcon sx={{ color: "text.secondary" }} />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          <Typography variant="body1">
                            {option.structured_formatting.main_text}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                />
                {isEditingAddress && fullAddress && (
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleCancelModification}
                    sx={{ marginLeft: "16px" }}
                  >
                    Annulla
                  </Button>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default AddressSection;
