import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Button, Typography } from "@mui/material";
import * as XLSX from "xlsx"; // Import the xlsx library

function FileUploader({ onFileAccepted }) {
  const [error, setError] = useState("");
  const [borderColor, setBorderColor] = useState("#ccc");

  // Function to validate Excel file structure
  const validateExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        try {
          // Convert the file to a binary string and parse it using XLSX
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });

          // Ensure the workbook contains at least one valid sheet
          if (
            workbook &&
            workbook.SheetNames &&
            workbook.SheetNames.length > 0
          ) {
            resolve(true); // If the file has sheets, it's valid
          } else {
            reject(false); // No sheets found, invalid Excel file
          }
        } catch (error) {
          // Parsing failed, the file is invalid
          reject(false);
        }
      };

      reader.onerror = () => reject(false);

      // Read the file as an array buffer (required by XLSX)
      reader.readAsArrayBuffer(file);
    });
  };

  // Function to check the magic number for Excel files
  const checkFileSignature = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        console.log("Magic number detected:", header); // Debug log

        // Check if the first two bytes match '50 4B' (common for ZIP formats like .xlsx)
        const isZIP = header.startsWith("504b"); // Less strict check for ZIP-based formats like .xlsx

        const isXLS = header === "d0cf11e0"; // XLS files start with 'd0cf11e0'

        if (isZIP || isXLS) {
          resolve(true); // Valid Excel file
        } else {
          reject(false); // Invalid Excel file
        }
      };
      reader.onerror = () => reject(false);
      reader.readAsArrayBuffer(file.slice(0, 4)); // Only read the first 4 bytes
    });
  };

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];

      // **Check if the file is empty**
      if (file.size === 0) {
        setError("Il file è vuoto."); // Italian message for empty file
        setBorderColor("red");
        return;
      }

      try {
        // First check the magic number
        const hasValidSignature = await checkFileSignature(file);
        if (!hasValidSignature) {
          setError("Il file non è un file Excel valido.");
          setBorderColor("red");
          return;
        }

        // Validate the file content using XLSX
        const isValid = await validateExcelFile(file);
        if (isValid) {
          setError("");
          setBorderColor("#ccc");
          onFileAccepted(acceptedFiles); // Pass the valid file to the parent
        } else {
          setError("Il file non è un file Excel valido."); // Invalid Excel file
          setBorderColor("red");
        }
      } catch {
        setError("Il file non è un file Excel valido."); // Invalid Excel file
        setBorderColor("red");
      }
    },
    [onFileAccepted]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected: () => {
      setError("Il file deve essere in formato .xls o .xlsx");
      setBorderColor("red");
    },
    accept: ".xls,.xlsx",
    noClick: true,
    noKeyboard: true,
  });

  return (
    <Box>
      <Box
        {...getRootProps()}
        sx={{
          border: `2px dashed ${borderColor}`,
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          borderRadius: "4px",
        }}
      >
        <input {...getInputProps()} />
        <Typography variant="h6">Trascina qui un file Excel</Typography>
        <Typography variant="body2" my={4}>
          Oppure
        </Typography>
        <Button variant="contained" onClick={open}>
          Seleziona un file
        </Button>
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ marginTop: "10px" }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}

export default FileUploader;
