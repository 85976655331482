// DynamicTable.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
  Button,
  CardHeader,
  Card as MuiCard,
  CardContent,
} from "@mui/material";
import * as Icons from "@mui/icons-material"; // Importazione dinamica delle icone
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const Card = styled(MuiCard)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

// Definizione degli handler
const handlers = {
  handleRenew: (rowData) => {
    // Logica per rinnovare la copertura
    console.log("Rinnova copertura per:", rowData);
  },
  handleViewQuote: (rowData) => {
    // Logica per visualizzare il preventivo
    console.log("Visualizza preventivo per:", rowData);
  },
  handleViewControl: (rowData) => {
    // Logica per visualizzare i dettagli del controllo
    console.log("Visualizza controllo per:", rowData);
  },
  handleViewRequest: (rowData) => {
    // Logica per visualizzare i dettagli della richiesta
    console.log("Visualizza richiesta per:", rowData);
  },
};

function DynamicTable({ tableConfig }) {
  // Funzione per renderizzare un singolo elemento in base al tipo
  const renderCellContent = (renderConfig, cellData, rowData) => {
    if (!renderConfig || !renderConfig.type) {
      return cellData;
    }

    switch (renderConfig.type) {
      case "chip":
        return (
          <Chip
            label={cellData}
            color={renderConfig.options.color || "default"}
            variant={renderConfig.options.variant || "outlined"}
          />
        );
      case "icon":
        const IconComponent = Icons[renderConfig.options.iconName];
        return IconComponent ? (
          <IconComponent color={renderConfig.options.color || "inherit"} />
        ) : (
          cellData
        );
      case "button":
        return (
          <Button
            variant={renderConfig.options.variant || "contained"}
            color={renderConfig.options.color || "primary"}
            onClick={() => {
              if (
                renderConfig.options.onClick &&
                handlers[renderConfig.options.onClick]
              ) {
                handlers[renderConfig.options.onClick](rowData);
              } else {
                console.warn(
                  `Handler ${renderConfig.options.onClick} non definito.`
                );
              }
            }}
          >
            {renderConfig.options.text || "Action"}
          </Button>
        );
      case "text":
      default:
        return cellData;
    }
  };

  return (
    <Card mb={6}>
      <CardHeader title={tableConfig.tableTitle} />
      <CardContent>
        <TableWrapper>
          <Table aria-label={tableConfig.tableTitle}>
            <TableHead>
              <TableRow>
                {tableConfig.columns.map((col, index) => (
                  <TableCell key={index} sx={{ fontWeight: "bold" }}>
                    {col.header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableConfig.data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {tableConfig.columns.map((col, colIndex) => (
                    <TableCell key={colIndex}>
                      {col.render
                        ? renderCellContent(col.render, row[col.accessor], row)
                        : row[col.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </CardContent>
    </Card>
  );
}

export default DynamicTable;
