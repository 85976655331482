import * as XLSX from "xlsx";

export const downloadDocument = (data, name) => {
  return new Promise((resolve, reject) => {
    try {
      const exportToExcel = (data, fileName) => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Convert your data into a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate the Excel file and download it
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
        resolve(); // Notify that the document download is complete
      };

      const reformatData = (data) => {
        // Helper function to flatten an object by removing keys with single object arrays
        const flattenObject = (obj, parentKey = "") => {
          let flattenedObj = {};

          for (const key in obj) {
            const newKey = parentKey ? `${parentKey}_${key}` : key; // Create unique key by combining parent and current key

            if (Array.isArray(obj[key])) {
              if (obj[key]?.length === 1 && typeof obj[key][0] === "object") {
                // If array contains a single object, extract its key-value pairs
                flattenedObj = {
                  ...flattenedObj,
                  ...flattenObject(obj[key][0], newKey),
                };
              } else {
                // If array contains multiple objects, flatten each object and append unique indices to keys
                let tempFlattened = {};
                obj[key].forEach((item, index) => {
                  tempFlattened = {
                    ...tempFlattened,
                    ...flattenObject(item, `${newKey}_${index + 1}`),
                  };
                });
                flattenedObj = { ...flattenedObj, ...tempFlattened }; // Merge after the loop
              }
            } else if (typeof obj[key] === "object" && obj[key] !== null) {
              // Recursively flatten nested objects
              flattenedObj = {
                ...flattenedObj,
                ...flattenObject(obj[key], newKey),
              };
            } else {
              // Keep simple key-value pairs
              flattenedObj[newKey] = obj[key];
            }
          }

          return flattenedObj;
        };

        // Apply the flattening function to each object in the array
        return data?.map((item) => flattenObject(item));
      };

      exportToExcel(reformatData(data), name);
    } catch (error) {
      reject(error);
    }
  });
};
