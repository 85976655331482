import React from "react";
import {
  FilterList as FilterIcon,
  FilterListOff as FilterOffIcon,
} from "@mui/icons-material";
import {
  useTheme,
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import CustomSelect from "../../components/CustomSelect";
import { CustomSelectMenuProps } from "./constant";

function Filters({
  filtersActive,
  switchFilterActive,
  handleApplyFilters,
  handleRemoveFilters,
  filtersLoading,
  isApplyFiltersButtonDisabled,
  filterOptions,
  filterData,
  setFilterData,
  canClearFilters,
  isRemovingFilters,
}) {
  const theme = useTheme();
  const hasFilters = filterOptions?.length > 0;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const renderFilters = () => {
    return filterOptions?.map((option, index) => {
      const largeSelect =
        option.label === "Agenti" || option.label === "Aziende";
      const smallInput = option.label === "Targa";

      if (option.type === "text") {
        return (
          <TextField
            label={option.label}
            name={option.label.toLowerCase()}
            value={
              filterData && filterData[option?.label?.toLowerCase()]
                ? filterData[option?.label?.toLowerCase()]
                : ""
            }
            variant="outlined"
            onChange={(event) => handleChange(event, option.label)}
            sx={{
              width: "200px",
              margin: "8px",
              minWidth: "200px",
              [theme.breakpoints.down(1500)]: {
                width: smallInput ? "120px" : "180px",
                minWidth: smallInput ? "120px" : "180px",
              },
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "0",
                margin: "8px 0",
                width: "100%",
              },
            }}
          />
        );
      } else if (option.type === "list") {
        return (
          <CustomSelect
            key={index}
            disabled={false}
            label={option.label}
            name={option.label.toLowerCase()}
            options={option.dataset}
            value={
              filterData && filterData[option?.label?.toLowerCase()]
                ? filterData[option?.label?.toLowerCase()]
                : ""
            }
            onChange={(event) => handleChange(event, option.label)}
            MenuProps={CustomSelectMenuProps}
            sx={{
              width: "200px",
              margin: "8px",
              minWidth: "200px",
              [theme.breakpoints.down(1500)]: {
                width: largeSelect ? "180px" : "150px",
                minWidth: largeSelect ? "180px" : "150px",
              },
              [theme.breakpoints.down("md")]: {
                marginBottom: "8px",
              },
              [theme.breakpoints.down("sm")]: {
                marginBottom: "0",
                margin: "8px 0",
                width: "100%",
              },
            }}
          />
        );
      }
      return null; // Skip unknown types
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          padding: "8px 16px",
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "start",
            padding: "1rem",
          },
        }}
      >
        {!filtersActive ? (
          <Stack
            direction="row"
            sx={{
              cursor: "pointer",
              userSelect: "none",
              padding: "15px 0",
              [theme.breakpoints.down("md")]: {
                padding: "0",
              },
            }}
            onClick={switchFilterActive}
          >
            <FilterIcon
              sx={{
                marginRight: "16px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "8px",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "4px",
                },
              }}
            />
            <Typography variant="h4" mr={4}>
              Filtra
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            sx={{
              cursor: "pointer",
              userSelect: "none",
              padding: "15px 0",
              [theme.breakpoints.down("md")]: {
                padding: "0",
              },
            }}
            onClick={switchFilterActive}
          >
            <FilterOffIcon
              sx={{
                marginRight: "16px",
                [theme.breakpoints.down("md")]: {
                  marginBottom: "8px",
                },
                [theme.breakpoints.down("sm")]: {
                  marginBottom: "4px",
                },
              }}
            />
            {/* <Typography variant="h4" mr={4}>
              Filtra
            </Typography> */}
          </Stack>
        )}

        {filtersActive && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                flexWrap: "nowrap",
                alignItems: "end",
                width: "100%",
              },
            }}
          >
            {/* Render dynamic filters */}
            {hasFilters ? renderFilters() : "Non ci sono filtri disponibili."}

            <Box
              sx={{
                marginBottom: "1px",
                [theme.breakpoints.down("md")]: {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: canClearFilters ? "space-between" : "end",
                  width: "100%",
                },
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "row",
                },
              }}
            >
              {canClearFilters && (
                <Button
                  variant="outlined"
                  disabled={isRemovingFilters}
                  onClick={handleRemoveFilters}
                  sx={{
                    margin: "8px",
                    [theme.breakpoints.down("md")]: {
                      marginBottom: "8px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      margin: "4px 0 0 0",
                    },
                  }}
                >
                  {isRemovingFilters ? (
                    <CircularProgress size={22} />
                  ) : (
                    "Rimuovi filtri"
                  )}
                </Button>
              )}
              {hasFilters && (
                <Button
                  variant="contained"
                  type="submit"
                  disabled={Boolean(isApplyFiltersButtonDisabled)}
                  sx={{
                    margin: "8px",
                    [theme.breakpoints.down("md")]: {
                      marginBottom: "8px",
                    },
                    [theme.breakpoints.down("sm")]: {
                      margin: "4px 0 0 0",
                    },
                  }}
                >
                  {filtersLoading ? <CircularProgress size={22} /> : "Cerca"}
                </Button>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </form>
  );
}

export default Filters;
