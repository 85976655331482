// Section.js
import React from "react";
import { Grid } from "@mui/material";
import DynamicForm from "./DynamicForm";
import DynamicTable from "./DynamicTable";
import TabsComponent from "./TabsComponent";
import DynamicChart from "./DynamicChart";
import DynamicDataGrid from "./DynamicDataGrid";

function Section({ section }) {
  const renderElement = (element, index) => {
    if (element.custom) {
      return (
        <div key={index}>
          <p>Elemento custom: {element.type}</p>
        </div>
      );
    }

    switch (element.type) {
      case "form":
        return <DynamicForm key={index} formConfig={element.config} />;
      case "table":
        return <DynamicTable key={index} tableConfig={element.config} />;
      case "tabs":
        return <TabsComponent key={index} tabsConfig={element.config.tabs} />;
      case "chart":
        return <DynamicChart key={index} chartConfig={element.config} />;
      case "datagrid":
        return <DynamicDataGrid key={index} gridConfig={element.config} />;
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction={section.layout === "row" ? "row" : "column"}
      spacing={6}
    >
      {section.elements.map((element, index) => (
        <Grid
          item
          key={index}
          xs={(element.grid && element.grid.xs) || 12}
          sm={(element.grid && element.grid.sm) || 12}
          md={(element.grid && element.grid.md) || 12}
          lg={(element.grid && element.grid.lg) || 12}
          xl={(element.grid && element.grid.xl) || 12}
        >
          {renderElement(element, index)}
        </Grid>
      ))}
    </Grid>
  );
}

export default Section;
