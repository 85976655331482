import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { spacing } from "@mui/system";
import Modal from "./nuovoPreventivo";
import CircularProgress from "@mui/material/CircularProgress";
import Filters from "./Filters";
import { useGetRcTableColumns } from "./columns";
import DoughnutChart from "./DoughnutChart";
import BarChart from "./BarChart";
import { useData } from "./useData";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function RCAuto() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [filtersActive, setFiltersActive] = useState(isDesktop);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const columns = useGetRcTableColumns();

  const {
    handlePageChange,
    handlePageSizeChange,
    handleApplyFilters,
    handleRemoveFilters,
    doughnutChartData,
    barChartData,
    rcData,
    totalRows,
    loading,
    filtersLoading,
    isApplyFiltersButtonDisabled,
    pageNumber,
    rowsPerPage,
    filterOptions,
    filterData,
    setFilterData,
    setFilterByDate,
    canClearFilters,
    isRemovingFilters,
  } = useData();

  const switchFilterActive = () => {
    setFiltersActive((prev) => !prev);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  return (
    <React.Fragment>
      <Modal open={isModalOpen} setOpen={setIsModalOpen} />
      <Helmet title="RC Auto" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          RC Auto
        </Typography>

        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          Nuovo preventivo
        </Button>
      </Box>

      <Divider mt={4} />

      {loading ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          {/* Charts */}
          <Grid
            container
            wrap="wrap"
            sx={{
              margin: isDesktop ? "0 -16px" : "0",
              padding: "0",
              width: isDesktop ? "calc(100% + 16px)" : "100%",
            }}
            spacing={isDesktop ? 4 : 0}
          >
            <Grid item lg={4} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <DoughnutChart
                doughnutChartData={doughnutChartData}
                filterOptions={filterOptions}
                setFilterByDate={setFilterByDate}
              />
            </Grid>
            <Grid item lg={8} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <BarChart
                barChartData={barChartData}
                filterOptions={filterOptions}
                setFilterByDate={setFilterByDate}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <>
              <Card sx={{ marginBottom: "1rem" }}>
                <Filters
                  filtersActive={filtersActive}
                  switchFilterActive={switchFilterActive}
                  handleApplyFilters={handleApplyFilters}
                  handleRemoveFilters={handleRemoveFilters}
                  filtersLoading={filtersLoading}
                  isApplyFiltersButtonDisabled={isApplyFiltersButtonDisabled}
                  filterOptions={filterOptions}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  canClearFilters={canClearFilters}
                  isRemovingFilters={isRemovingFilters}
                />
              </Card>

              <Card mb={6}>
                <Paper>
                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      autoHeight
                      pageSize={rowsPerPage}
                      onPageSizeChange={handlePageSizeChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      paginationMode="server"
                      rowCount={totalRows}
                      page={pageNumber - 1}
                      onPageChange={(params) => handlePageChange(params)}
                      getRowId={(usersList) => usersList.idUID}
                      rows={rcData}
                      columns={columns}
                      editMode="row"
                      allowUpdating={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      disableColumnMenu
                      disableSelectionOnClick
                      localeText={{ noRowsLabel: "Non ci sono risultati" }}
                      sx={{
                        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                        "& .MuiDataGrid-footerContainer": {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Card>
            </>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default RCAuto;
