import { Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import BigNumber from "./BigNumber";
import BarChart from "./BarChart";

const Charts = ({ barChartData, totalRows }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid
      container
      sx={{
        margin: isDesktop ? "0 -16px" : "0",
        padding: "0",
        width: isDesktop ? "calc(100% + 16px)" : "100%",
      }}
      spacing={isDesktop ? 4 : 0}
    >
      <Grid item lg={4} md={4} sm={12} xs={12} sx={{ flex: 1 }}>
        <BigNumber totalRows={totalRows} />
      </Grid>
      <Grid item lg={8} md={8} sm={12} xs={12} sx={{ flex: 1 }}>
        <BarChart barChartData={barChartData} />
      </Grid>
    </Grid>
  );
};

export default Charts;
