import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  useTheme,
} from "@mui/material";
import FileUploader from "./FileUploader";
import NameSection from "./NameSection";
import Options from "./Options";
import Results from "./Results";
import { useExcelParser } from "./useExcelParser";
import ConfrimationStep from "./ConfirmationStep";
import useAuth from "../../../../hooks/useAuth";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../../redux/slices/snackbar";

function Modal({ open, setOpen, getFleet }) {
  const isUnder600px = useMediaQuery("(max-width:600px)");
  const isUnder1000px = useMediaQuery("(max-width:1000px)");
  const [file, setFile] = useState(null);
  const [name, setName] = useState();
  const [tableData, setTableData] = useState([]);
  const [loadingFleet, setLoadingFleet] = useState(false);
  const [uploadConfirmation, setUploadConfirmation] = useState(false);
  const [fleetMaxLengthExtended, setFleetMaxLengthExtended] = useState(false);
  const [userHasCheckedDocumentResult, setUserHasCheckedDocumentResult] =
    useState(false);
  const [optionsData, setOptionsData] = useState({
    page: null,
    column: null,
    row: null,
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const { flotteNewUpdate } = useAuth();
  const { excelData, extractColumnData, columnData } = useExcelParser(file);
  let maxWidth;
  if (isUnder600px) {
    maxWidth = "xs";
  } else if (isUnder1000px) {
    maxWidth = "sm";
  } else {
    maxWidth = uploadConfirmation ? "sm" : "md";
  }
  // Regular expression to validate the license plate
  const plateRegex =
    /^(?:[A-Z]{2}\d{3}[A-Z]{2}|[A-Z]{2}\d{5}|[A-Z]{3}\d{5}|[A-Z]{2}\d{6})$/;

  const handleSnackbar = (message, severity) => {
    dispatch(showSnackbar({ message: message, severity: severity }));
  };

  // Handle file upload
  const handleFileAccepted = (files) => {
    setFile(files[0]);
  };

  // Update table data when columnData changes (after extraction)
  useEffect(() => {
    if (columnData) {
      const formattedData = columnData
        .map((value, index) => ({
          row: index + 1,
          targa: value || "Empty",
        }))
        .filter((row) => row.targa !== "Empty" && row.targa.trim() !== "")
        .map((row) => ({
          ...row,
          error: !plateRegex.test(row.targa), // Validate targa
          message: plateRegex.test(row.targa)
            ? "Formato targa corretto"
            : "Formato targa errato",
        }));

      const startRow = Math.max(1, parseInt(optionsData.row, 10)) - 1;

      const dataRows = formattedData.slice(startRow);

      setTableData(dataRows); // Update table data once columnData is available
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnData]);

  // Handle extracting data when the user clicks the "Elabora" button
  const handleExtractData = () => {
    const { page, column, row } = optionsData;
    setUserHasCheckedDocumentResult(true);

    // Find the index of the selected sheet by its name
    const sheetIndex = excelData.findIndex((sheet) => sheet.name === page);

    if (sheetIndex !== -1 && column !== null && row !== null) {
      // Ensure column and row are at least 1 when processing
      const processedColumn = Math.max(1, parseInt(column, 10));
      const processedRow = Math.max(1, parseInt(row, 10));

      console.log(
        `Extracting data from sheet ${page} (index: ${sheetIndex}), column ${processedColumn}, row ${processedRow}`
      );

      // Extract data for the selected column
      extractColumnData(
        sheetIndex,
        processedColumn - 1 // Convert to zero-based index
      );
    } else {
      console.warn("Invalid page, column, or row selection.");
    }
  };

  const toggleConfrimationStep = () => {
    setFleetMaxLengthExtended(false);
    setUploadConfirmation((prev) => !prev);
  };

  const resetState = () => {
    setFile(null);
    setName(null);
    setTableData([]);
    setUploadConfirmation(false);
    setUserHasCheckedDocumentResult(false);
    setFleetMaxLengthExtended(false);
    setOptionsData({
      page: null,
      column: null,
      row: null,
    });
    setOpen(false);
  };

  // Handle closing the modal
  const handleClose = (_, reason) => {
    if (reason && reason === "backdropClick") return;
    resetState();
  };

  // Handle input changes
  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeOptions = (e) => {
    const { name, value } = e.target;
    setOptionsData((prev) => ({ ...prev, [name]: value }));
  };

  const handleUploadData = () => {
    const validatedPlates = tableData
      .filter((plate) => !plate.error)
      .map((plate) => plate.targa);

    // Check if there are validated plates, the name is not empty, and the length is less than or equal to 1000
    if (validatedPlates.length > 0 && name && validatedPlates.length <= 1000) {
      setLoadingFleet(true);

      flotteNewUpdate(name, validatedPlates.length, validatedPlates)
        .then((response) => {
          resetState();
          if (response.status === 1) {
            getFleet();
            handleSnackbar(
              response.message || "Flotta caricata correttamente",
              "success"
            );
          } else {
            handleSnackbar(
              response.message || "Qualcosa è andato storto",
              "error"
            );
          }
        })
        .catch((err) => {
          handleSnackbar(err.message || "Qualcosa è andato storto", "error");
        })
        .finally(() => {
          setLoadingFleet(false);
        });
    } else if (validatedPlates.length > 1000) {
      setFleetMaxLengthExtended(true);
    } else {
      handleSnackbar("Nome flotta mancante", "error");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle id="form-dialog-title">Crea Flotta</DialogTitle>
      <DialogContent>
        {!file ? (
          <FileUploader onFileAccepted={handleFileAccepted} />
        ) : uploadConfirmation ? (
          <ConfrimationStep
            theme={theme}
            tableData={tableData}
            fleetMaxLengthExtended={fleetMaxLengthExtended}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "8px",
              marginTop: "8px",
            }}
          >
            <NameSection
              theme={theme}
              name={name}
              handleChangeName={handleChangeName}
              fileName={file.name}
            />
            <Divider sx={{ margin: "16px 0" }} />
            <Options
              handleChangeOptions={handleChangeOptions}
              optionsData={optionsData}
              sheetNames={excelData?.map((sheet) => sheet.name)}
              theme={theme}
              handleExtractData={handleExtractData} // Handle extraction only when button is clicked
            />
            <Divider sx={{ margin: "16px 0" }} />
            <Results data={tableData} theme={theme} />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={uploadConfirmation ? toggleConfrimationStep : handleClose}
          color="primary"
          sx={{ marginRight: "8px" }}
        >
          {uploadConfirmation ? "Indietro" : "Chiudi"}
        </Button>

        {file && (
          <Button
            disabled={
              !tableData?.some((item) => !item.error) ||
              !name ||
              !userHasCheckedDocumentResult ||
              loadingFleet
            }
            type="submit"
            color="primary"
            variant="contained"
            onClick={
              !uploadConfirmation ? toggleConfrimationStep : handleUploadData
            }
            sx={{ marginRight: "16px" }}
          >
            {loadingFleet ? (
              <CircularProgress size={22} />
            ) : uploadConfirmation ? (
              "Conferma caricamento"
            ) : (
              "Crea"
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
