import { useState, useEffect, useRef, useCallback } from "react";
import { debounce } from "@mui/material/utils";

const GOOGLE_MAPS_API_KEY = "AIzaSyCQhJ5S5pfUEhrXR8LXarlu4BpCtW5hlqc";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

export default function useGooglePlaces() {
  const autocompleteService = useRef(null);
  const placesService = useRef(null);
  const loaded = useRef(false);

  const [searching, setSearching] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector("#google-maps")) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
          document.querySelector("head"),
          "google-maps"
        );
      }

      loaded.current = true;
    }
  }, []);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
    if (!placesService.current) {
      return undefined;
    }

    // Check if inputValue has 10 or more characters
    if (inputValue.length < 15) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    setSearching(true);

    const fetch = debounce((request, callback) => {
      autocompleteService.current.getPlacePredictions(request, callback);
    }, 400);

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
        setSearching(false);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  const getPlaceDetails = (placeId, callback) => {
    placesService.current.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        callback(place);
      } else {
        console.error("Failed to get place details:", status);
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetInputValue = useCallback(
    debounce((newInputValue) => {
      setInputValue(newInputValue);
    }, 2000),
    []
  );

  return {
    searching,
    options,
    value,
    setValue,
    debouncedSetInputValue,
    getPlaceDetails,
  };
}
