// DynamicChart.js
import React from "react";
import { Pie, Bar, Line } from "react-chartjs-2";
import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
} from "chart.js";
import styled from "@emotion/styled";

// Registrazione dei componenti di Chart.js
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);

function DynamicChart({ chartConfig }) {
  const { chartTitle, chartType, data, height } = chartConfig;

  const ChartWrapper = styled.div`
  height: ${height},
  position: relative;
`;

  // Mappa i tipi di grafici a componenti specifici di Chart.js
  const renderChart = () => {
    switch (chartType) {
      case "pie":
        return <Pie data={data} />;
      case "bar":
        return <Bar data={data} />;
      case "line":
        return <Line data={data} />;
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardHeader title={chartTitle} />
      <CardContent>
        <ChartWrapper>{renderChart()}</ChartWrapper>
      </CardContent>
    </Card>
  );
}

export default DynamicChart;
