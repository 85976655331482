import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Bar } from "react-chartjs-2";
import { rgba } from "polished";
import { spacing } from "@mui/system";
import { withTheme } from "@emotion/react";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 269px;
  position: relative;
`;

const BarChart = ({ barChartData }) => {
  const theme = useTheme();
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Totale",
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        hoverBackgroundColor: theme.palette.secondary.main,
        hoverBorderColor: theme.palette.secondary.main,
        data: [],
        barPercentage: 0.4,
        categoryPercentage: 0.5,
      },
      {
        label: "Totale",
        backgroundColor: rgba(theme.palette.secondary.main, 0.33),
        borderColor: rgba(theme.palette.secondary.main, 0.33),
        hoverBackgroundColor: rgba(theme.palette.secondary.main, 0.33),
        hoverBorderColor: rgba(theme.palette.secondary.main, 0.33),
        data: [],
        barPercentage: 0.4,
        categoryPercentage: 0.5,
        borderRadius: 6,
      },
    ],
  });

  useEffect(() => {
    if (barChartData) {
      const labels = barChartData.map((item) => item.Giorno);
      const dataValues = barChartData.map((item) => item.Tot);

      setData({
        labels,
        datasets: [
          {
            ...data.datasets[0],
            data: dataValues,
          },
          {
            ...data.datasets[1],
            data: [],
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barChartData, theme.palette.secondary.main]);

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        color: theme.palette.primary.main,
        anchor: "end",
        align: "start",
        offset: -20,
        formatter: (value) => value,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
      },
      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
      },
    },
  };

  return (
    <Card>
      <CardHeader title="Trend visure" />
      <CardContent>
        <ChartWrapper>
          <Bar data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
