import React from "react";
import { Box, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

function ConfirmationStep({ theme, tableData, fleetMaxLengthExtended }) {
  // Filter out the rejected license plates
  const validatedPlates = tableData.filter((item) => item.error === false);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" mb={2} mt={2}>
        Si desidera procedere con il caricamento delle targe valide?
      </Typography>
      <Typography>
        {`Sono state trovate ${validatedPlates?.length} targhe valide da un totale di ${tableData?.length}.`}
      </Typography>
      {fleetMaxLengthExtended && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          my={2}
        >
          <ErrorIcon
            sx={{ marginRight: "8px" }}
            style={{ color: theme.palette.error.main }}
          />
          <Typography
            sx={{ color: theme.palette.error.main, marginTop: "2px" }}
          >
            Si possono caricare flotte con un massimo di 1000 targhe
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ConfirmationStep;
