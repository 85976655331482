import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";

export const useData = () => {
  const [filterData, setFilterData] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const [filterByDate, setFilterByDate] = useState(null);
  const [doughnutChartData, setDoughnutChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [rcData, setRcData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [isTableChanging, setIsTableChanging] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [isRemovingFilters, setIsRemovingFilters] = useState(false);
  const [canClearFilters, setCanClearFilters] = useState(false);
  const [isApplyFiltersButtonDisabled, setIsApplyFiltersButtonDisabled] =
    useState(true);
  const { getRcData, getData } = useAuth();

  // Side Effect that handles the get rc list api request initially when the page loads and then when the page number or rows change
  useEffect(() => {
    setIsTableChanging(true);
    getRcData(
      rowsPerPage,
      pageNumber,
      null,
      filterByDate ? { ...filterData, aging: filterByDate } : filterData
    )
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
          setIsTableChanging(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, rowsPerPage]);

  // Side Effect that get filters data api which runs only once when the page loads and in response sets the filters.
  useEffect(() => {
    getData({ action: "rcAutoGetFilter" }).then((response) => {
      if (response.status === 1) {
        setFilterOptions(response.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Side Effect that checks if the user has set filters and in truly it enables the Search button
  useEffect(() => {
    if (filterData) {
      hasFilters()
        ? setIsApplyFiltersButtonDisabled(false)
        : setIsApplyFiltersButtonDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData]);

  useEffect(() => {
    filterByDate && handleApplyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByDate]);

  // Function that checks if the user has set filters
  const hasFilters = () => {
    if (!filterData) {
      return false;
    }

    return Object.keys(filterData)?.some(
      (key) =>
        filterData[key] !== undefined &&
        filterData[key] !== null &&
        filterData[key] !== ""
    );
  };

  // function that changes the table page
  const handlePageChange = (newPage) => {
    if (isTableChanging) {
      return;
    } else {
      setPageNumber(newPage + 1);
    }
  };

  // function that changes the table rows (size)
  const handlePageSizeChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPageNumber(1);
  };

  // function that handles the get rc list api call with the filter the user has set and in response updates the list
  const handleApplyFilters = () => {
    setFiltersLoading(true);
    setIsTableChanging(true);
    setIsApplyFiltersButtonDisabled(true);
    setFilterByDate(null);

    getRcData(
      rowsPerPage,
      pageNumber,
      null,
      filterByDate ? { ...filterData, aging: filterByDate } : filterData
    )
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
          setCanClearFilters(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFiltersLoading(false);
        setIsTableChanging(false);
        setLoading(false);
      });
  };

  // function that handles the get rc list api call when the user removes all filters and in response the list is updated with the initial state
  const handleRemoveFilters = () => {
    setIsTableChanging(true);
    setFilterData(null);
    setIsRemovingFilters(true);

    getRcData(rowsPerPage, pageNumber, null)
      .then((response) => {
        if (response.status === 1) {
          setDoughnutChartData(response?.data?.graphCircleData);
          setBarChartData(response?.data?.graphBarData);
          setTotalRows(response?.data?.totalRows);
          setRcData(response?.data?.dataset);
          setCanClearFilters(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsRemovingFilters(false);
        setIsTableChanging(false);
        setFilterByDate(null);
      });
  };

  return {
    handlePageChange,
    handlePageSizeChange,
    handleApplyFilters,
    handleRemoveFilters,
    doughnutChartData,
    barChartData,
    rcData,
    totalRows,
    loading,
    filtersLoading,
    isApplyFiltersButtonDisabled,
    pageNumber,
    rowsPerPage,
    filterOptions,
    filterData,
    setFilterData,
    setFilterByDate,
    canClearFilters,
    isRemovingFilters,
  };
};
