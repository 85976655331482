import { withTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";

const Card = styled(MuiCard)(spacing);

const BigNumber = ({ totalRows }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const TableWrapper = styled.div`
    height: ${isDesktop ? "269px" : "100px"};
    position: relative;
  `;

  //Pulsante rimuovi i filtri, ragione sociale linkabile

  const checkRows = () => {
    if (totalRows < 10000) {
      return "8em";
    } else return "7em";
  };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
  }

  return (
    <Card mb={isDesktop ? 0 : 6}>
      <CardHeader title="Visure" />
      <CardContent>
        <TableWrapper>
          <Typography
            fontSize={isDesktop ? checkRows() : "5em"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            height="100%"
          >
            {numberWithCommas(totalRows)}
          </Typography>
        </TableWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BigNumber);
