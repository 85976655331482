import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";

import {
  Avatar,
  Button,
  CardContent,
  Chip,
  FormControl,
  Grid,
  Link,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { deepOrange } from "@mui/material/colors";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function DataGridUsers() {
  const navigate = useNavigate();
  const { getData } = useAuth();
  const [usersList, setUsersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [filteredUsersList, setFilteredUsersList] = useState(usersList);

  const updateState = (jsonData) => {
    setUsersList(jsonData);
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  useEffect(() => {
    const filteredList = usersList.filter((user) =>
      user.Nominativo.toLowerCase().includes(searchParam.toLowerCase())
    );
    setFilteredUsersList(filteredList);
  }, [searchParam, usersList]);

  useEffect(() => {
    getData({ action: "usersList" })
      .then((data) => {
        if (data.status === 0) {
          navigate("/private");
        }

        setIsLoading(true);
        return data.data;
      })
      .catch((e) => {
        return getData({ action: "usersList" });
      })
      .then((data) => {
        if (!data.alreadySet) {
          updateState(data);
        }
      })
      .catch((e) => {
        console.log("Reload failed");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [pageSize, setPageSize] = useState(5);

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const renderChip = (value) => {
    let chipColor;

    if (value === "Attivo") {
      chipColor = "success";
    } else if (value === "Non Attivo") {
      chipColor = "error";
    } else {
      chipColor = "default";
    }

    return (
      <Chip
        style={{ color: "white" }}
        label={value}
        color={chipColor}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        size="small"
      />
    );
  };

  const columnsModelDataUsers = [
    {
      field: "Nominativo",
      headerName: "Nominativo",
      minWidth: 200,
      width: 300,
      flex: 1.5,
      renderCell: (params) => (
        <>
          <Avatar
            sx={{
              mr: 3,
              bgcolor: deepOrange[500],
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            {params.row.Avatar}
          </Avatar>
          <Link
            onClick={() => {
              navigate(`/pages/profile/${params.id}`, {
                state: { guiIdSubUser: params.id, typeuser: "super" },
              });
            }}
            style={{ cursor: "pointer" }}
          >
            {params.value}
          </Link>
        </>
      ),
    },
    {
      field: "Ruolo",
      headerName: "Ruolo",
      minWidth: 100,
      width: 100,
      flex: 0.5,
    },
    {
      field: "Stato",
      headerName: "Stato",
      minWidth: 150,
      width: 200,
      flex: 1,
      align: "center",
      renderCell: (params) => renderChip(params.value),
    },
    {
      field: "Azienda",
      headerName: "Azienda",
      minWidth: 150,
      width: 200,
      flex: 1,
    },
    {
      field: "Responsabile",
      headerName: "Responsabile",
      minWidth: 150,
      width: 200,
      flex: 1,
    },
    {
      field: "CreatoDa",
      headerName: "Creato da",
      minWidth: 150,
      width: 200,
      flex: 1,
    },
    {
      field: "DataCreazione",
      headerName: "Data creazione",
      minWidth: 150,
      width: 200,
      flex: 1,
    },
  ];

  return isLoading ? (
    <Card mb={6}>
      <CardContent pb={1}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Risultati: {usersList.length}
            </Typography>
          </Grid>
          <Stack direction={"row"}>
            <FormControl sx={{ maxHeight: "30px", maxWidth: "200px" }}>
              <TextField
                size="small"
                type="text"
                placeholder="Cerca per nominativo"
                onChange={(e) => handleSearch(e)}
                sx={{
                  marginRight: "2rem",
                  maxHeight: "30px",
                  "& .MuiInputBase-root": {
                    height: "30px",
                  },
                  "& .MuiInputBase-input": {
                    height: "100%",
                    padding: "0 14px",
                  },
                }}
              />
            </FormControl>
            <Grid item>
              <Button mr={2} variant="contained" color="primary" size="small">
                Nuovo utente
              </Button>
            </Grid>
          </Stack>
        </Grid>
      </CardContent>
      <Paper>
        <div style={{ width: "100%" }}>
          <DataGrid
            autoHeight
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            pagination
            getRowId={(usersList) => usersList.IdUID}
            rows={searchParam ? filteredUsersList : usersList}
            columns={columnsModelDataUsers}
            editMode="row"
            allowUpdating={true}
            rowModesModel={rowModesModel}
            onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
            experimentalFeatures={{ newEditingApi: true }}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
          />
        </div>
      </Paper>
    </Card>
  ) : null;
}

function Users(props) {
  return (
    <React.Fragment>
      <Helmet title="Utenti" />
      <Typography variant="h3" gutterBottom display="inline">
        Utenti
      </Typography>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <DataGridUsers />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
