import {
  websocketConnected,
  websocketDisconnected,
  websocketError,
  websocketMessageReceived,
} from "../slices/websocket";

let ws;

const websocketMiddleware = (store) => (next) => (action) => {
  if (action.type === "websocket/connect") {
    ws = new WebSocket(action.payload);

    ws.onopen = () => {
      store.dispatch(websocketConnected());
    };

    ws.onmessage = (event) => {
      store.dispatch(websocketMessageReceived(event.data));
    };

    ws.onclose = () => {
      store.dispatch(websocketDisconnected());
    };

    ws.onerror = (error) => {
      store.dispatch(websocketError(error.message));
    };
  }

  if (action.type === "websocket/disconnect") {
    if (ws) {
      ws.close();
    }
  }

  return next(action);
};

export default websocketMiddleware;
