import styled from "@emotion/styled";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { rowsIFRS } from "./rows";
import { ArrowDown, ArrowUp } from "react-feather";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const IFRS = ({ report }) => {
  const firstTableRows = rowsIFRS[0];
  const secondTableRows = rowsIFRS[1];
  const thirdTableRows = rowsIFRS[2];
  const fourthTableRows = rowsIFRS[3];

  const theme = useTheme();

  function filteredContoEconomico() {
    return report.localFinancialStatements?.filter(
      (statement) => statement.type === "LocalFinancialsIFRS"
    );
  }

  function renderTableCell(statement, key) {
    const value = statement
      ?.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");

    return (
      <Box textAlign="center" display="flex" justifyContent="center" gap={2}>
        {value ? value : 0}
      </Box>
    );
  }

  const formatNumber = (num) =>
    parseFloat(num?.toString().replace(/,/g, "") || 0);

  function calculatePercentageDifference(currentValue, previousValue, inverso) {
    if (currentValue == null || previousValue == null || previousValue === 0)
      return "";

    const current = formatNumber(currentValue);
    const previous = formatNumber(previousValue);

    const percentageDifference = Math.abs(
      Math.ceil(((current - previous) / previous) * 100)
    );

    const color =
      inverso === "si"
        ? current > previous
          ? "#ee2e24"
          : "#4dbb81"
        : current > previous
        ? "#4dbb81"
        : "#ee2e24";
    const Icon = current > previous ? ArrowUp : ArrowDown;

    return (
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        color={color}
      >
        <Icon size={10} />
        <Typography>{percentageDifference}%</Typography>
      </Box>
    );
  }

  function generateTableRow(row, key, numTable) {
    return (
      <TableRow key={key}>
        <TableCell width="30%">
          <Typography>{row[key]}</Typography>
        </TableCell>
        {filteredContoEconomico().map((statement, i) => {
          switch (numTable) {
            case 1: {
              const valueNum = statement.profitAndLoss?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.profitAndLoss?.[key];
              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 2: {
              const valueNum = statement.balanceSheet?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.balanceSheet?.[key];
              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {key === "tradePayables" ||
                    key === "bankLiabilities" ||
                    key === "otherLoansOrFinance" ||
                    key === "groupPayables" ||
                    key === "miscellaneousLiabilities" ||
                    key === "totalCurrentLiabilities" ||
                    key === "tradePayablesDueAfter1Year" ||
                    key === "bankLiabilitiesDueAfter1Year" ||
                    key === "otherLoansOrFinanceDueAfter1Year" ||
                    key === "groupPayablesDueAfter1Year" ||
                    key === "miscellaneousLiabilitiesDueAfter1Year" ||
                    key === "totalLongTermLiabilities" ||
                    key === "totalLiabilities"
                      ? calculatePercentageDifference(
                          valueNum,
                          valueNumNext,
                          "si"
                        )
                      : calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 3: {
              const valueNum = statement.otherFinancials?.[key];
              const nextStatement = filteredContoEconomico()[i + 1];
              const valueNumNext = nextStatement?.otherFinancials?.[key];
              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                    {calculatePercentageDifference(valueNum, valueNumNext)}
                  </Box>
                </TableCell>
              );
            }
            case 4: {
              const valueNum = statement.ratios?.[key];

              return (
                <TableCell width="25%" key={i}>
                  <Box
                    textAlign="center"
                    display="flex"
                    justifyContent="center"
                    gap={2}
                  >
                    {renderTableCell(valueNum, key)}
                  </Box>
                </TableCell>
              );
            }
            default:
              break;
          }
        })}
      </TableRow>
    );
  }

  return (
    <Grid container spacing={6} mt={0.5}>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Conto Economico (IFRS)</Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(firstTableRows, "revenue", 1)}
                  {generateTableRow(firstTableRows, "operatingCosts", 1)}
                  {generateTableRow(firstTableRows, "operatingProfit", 1)}
                  {generateTableRow(firstTableRows, "wagesAndSalaries", 1)}
                  {generateTableRow(firstTableRows, "depreciation", 1)}
                  {generateTableRow(firstTableRows, "amortisation", 1)}
                  {generateTableRow(firstTableRows, "financialIncome", 1)}
                  {generateTableRow(firstTableRows, "financialExpenses", 1)}
                  {generateTableRow(firstTableRows, "profitBeforeTax", 1)}
                  {generateTableRow(firstTableRows, "tax", 1)}
                  {generateTableRow(firstTableRows, "profitAfterTax", 1)}
                  {generateTableRow(firstTableRows, "otherAppropriations", 1)}
                  {generateTableRow(firstTableRows, "retainedProfit", 1)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Stato patrimoniale (IFRS)</Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(secondTableRows, "otherTangibleAssets", 2)}
                  {generateTableRow(secondTableRows, "totalTangibleAssets", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "otherIntangibleAssets",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "totalIntangibleAssets",
                    2
                  )}
                  {generateTableRow(secondTableRows, "loansToGroup", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "miscellaneousFixedAssets",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "totalOtherFixedAssets",
                    2
                  )}
                  {generateTableRow(secondTableRows, "totalFixedAssets", 2)}
                  {generateTableRow(secondTableRows, "workInProgress", 2)}
                  {generateTableRow(secondTableRows, "otherInventories", 2)}
                  {generateTableRow(secondTableRows, "totalInventories", 2)}
                  {generateTableRow(secondTableRows, "tradeReceivables", 2)}
                  {generateTableRow(secondTableRows, "groupReceivables", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "receivablesDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "miscellaneousReceivables",
                    2
                  )}
                  {generateTableRow(secondTableRows, "totalReceivables", 2)}
                  {generateTableRow(secondTableRows, "cash", 2)}
                  {generateTableRow(secondTableRows, "totalCurrentAssets", 2)}
                  {generateTableRow(secondTableRows, "totalAssets", 2)}
                  {generateTableRow(secondTableRows, "tradePayables", 2)}
                  {generateTableRow(secondTableRows, "bankLiabilities", 2)}
                  {generateTableRow(secondTableRows, "otherLoansOrFinance", 2)}
                  {generateTableRow(secondTableRows, "groupPayables", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "miscellaneousLiabilities",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "totalCurrentLiabilities",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "tradePayablesDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "bankLiabilitiesDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "otherLoansOrFinanceDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "groupPayablesDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "miscellaneousLiabilitiesDueAfter1Year",
                    2
                  )}
                  {generateTableRow(
                    secondTableRows,
                    "totalLongTermLiabilities",
                    2
                  )}
                  {generateTableRow(secondTableRows, "totalLiabilities", 2)}
                  {generateTableRow(secondTableRows, "calledUpShareCapital", 2)}
                  {generateTableRow(secondTableRows, "sharePremium", 2)}
                  {generateTableRow(secondTableRows, "revenueReserves", 2)}
                  {generateTableRow(secondTableRows, "otherReserves", 2)}
                  {generateTableRow(
                    secondTableRows,
                    "totalShareholdersEquity",
                    2
                  )}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">
                Altre attività finanziarie (IFRS)
              </Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateTableRow(thirdTableRows, "workingCapital", 3)}
                  {generateTableRow(thirdTableRows, "netWorth", 3)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Card sx={{ height: "100%" }}>
          <CardContent>
            <Box marginBottom={4}>
              <Typography fontSize="1rem">Indici (IFRS)</Typography>
            </Box>
            <TableWrapper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {filteredContoEconomico().map((statement) => {
                      const yearEndDate = new Date(
                        statement.yearEndDate
                      ).toLocaleDateString();
                      return (
                        <TableCell
                          style={{ minWidth: "10rem" }}
                          align="center"
                          key={yearEndDate}
                        >
                          {yearEndDate}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{
                      m: 4,
                      borderBottom: `1px solid ${
                        theme.palette.mode === "dark"
                          ? "rgba(81, 81, 81, 1)"
                          : "rgba(224, 224, 224, 1)"
                      }`,
                    }}
                  >
                    <TableCell>ANDAMENTO DELL'ATTIVITÀ COMMERCIALE</TableCell>
                  </TableRow>
                  {generateTableRow(fourthTableRows, "preTaxProfitMargin", 4)}
                  {generateTableRow(
                    fourthTableRows,
                    "returnOnCapitalEmployed",
                    4
                  )}
                  {generateTableRow(
                    fourthTableRows,
                    "returnOnTotalAssetsEmployed",
                    4
                  )}
                  {generateTableRow(
                    fourthTableRows,
                    "returnOnNetAssetsEmployed",
                    4
                  )}
                  {generateTableRow(
                    fourthTableRows,
                    "salesOrNetWorkingCapital",
                    4
                  )}
                  <TableRow
                    sx={{
                      m: 4,
                      borderBottom: `1px solid ${
                        theme.palette.mode === "dark"
                          ? "rgba(81, 81, 81, 1)"
                          : "rgba(224, 224, 224, 1)"
                      }`,
                    }}
                  >
                    <TableCell>EFFICIENZA OPERATIVA</TableCell>
                  </TableRow>
                  {generateTableRow(fourthTableRows, "stockTurnoverRatio", 4)}
                  {generateTableRow(fourthTableRows, "debtorDays", 4)}
                  {generateTableRow(fourthTableRows, "creditorDays", 4)}
                  <TableRow
                    sx={{
                      m: 4,
                      borderBottom: `1px solid ${
                        theme.palette.mode === "dark"
                          ? "rgba(81, 81, 81, 1)"
                          : "rgba(224, 224, 224, 1)"
                      }`,
                    }}
                  >
                    <TableCell>SOLIDITÀ DI BREVE PERIODO</TableCell>
                  </TableRow>
                  {generateTableRow(fourthTableRows, "currentRatio", 4)}
                  {generateTableRow(
                    fourthTableRows,
                    "liquidityRatioOrAcidTest",
                    4
                  )}
                  {generateTableRow(fourthTableRows, "currentDebtRatio", 4)}
                  <TableRow
                    sx={{
                      m: 4,
                      borderBottom: `1px solid ${
                        theme.palette.mode === "dark"
                          ? "rgba(81, 81, 81, 1)"
                          : "rgba(224, 224, 224, 1)"
                      }`,
                    }}
                  >
                    <TableCell>SOLIDITÀ DI LUNGO PERIODO</TableCell>
                  </TableRow>
                  {generateTableRow(fourthTableRows, "gearing", 4)}
                  {generateTableRow(fourthTableRows, "equityInPercentage", 4)}
                  {generateTableRow(fourthTableRows, "totalDebtRatio", 4)}
                </TableBody>
              </Table>
            </TableWrapper>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default IFRS;
