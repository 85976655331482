import { Chip as MuiChip } from "@mui/material";
import styled from "@emotion/styled";
import { red, blue, grey, green, yellow, orange } from "@mui/material/colors";

export const ChipRed = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: ${red[500]};
  color: #fff;
`;
export const ChipBlue = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: #0f74b9;
  color: #fff;
`;
export const ChipGrey = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: ${grey[400]};
  color: #000;
`;
export const ChipGreen = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: ${green[700]};
  color: #fff;
`;
export const ChipYellow = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: ${yellow[400]};
  color: #000;
`;
export const ChipOrange = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  font-weight: 600;
  background-color: ${orange[400]};
  color: #000;
`;
