// DynamicDataGrid.js
import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Avatar, Card as MuiCard, CardContent, CardHeader, Paper, Typography, IconButton, Link, TextField } from '@mui/material';
import * as Icons from '@mui/icons-material';
import styled from '@emotion/styled';
import { spacing } from '@mui/system';
import axios from 'axios';

const Card = styled(MuiCard)(spacing);

function DynamicDataGrid({ gridConfig }) {
  const { gridTitle, columns, rows, rowsPerPageOptions, checkboxSelection,  filterField, apiConfig } = gridConfig;
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSizeState, setPageSizeState] = useState(rowsPerPageOptions[0] || 5);
  const token = sessionStorage.getItem("token");
  const [paginatedRows, setPaginatedRows] = useState([]);
  const [totalRowCount, setTotalRowCount] = useState(rows.length);
  const { endpoint, queryParams, responseField } = apiConfig || {};

  // Funzione per sostituire i segnaposto nei parametri di query
  const prepareQueryParams = (queryParams, replacements) => {
    const updatedParams = {"token":token, data:{}};
    for (let [key, value] of Object.entries(queryParams)) {
      if (typeof value === 'string' && value.includes("{")) {
        // Sostituisce il placeholder con il valore di replacements
        for (let [placeholder, replacementValue] of Object.entries(replacements)) {
          value = value.replace(`{${placeholder}}`, replacementValue);
        }
      }
      updatedParams.data[key] = value;
    }
    return updatedParams;
  };

  // Effettua una chiamata API o ricerca locale in base alla presenza di apiConfig
  useEffect(() => {
    const fetchData = async () => {
      if (apiConfig) {
        try {
          const preparedParams = prepareQueryParams(queryParams, {
            currentPage: currentPage + 1,
            pageSize: pageSizeState,
            filterText: searchText
          });

          const response = await axios.post(endpoint, preparedParams);
          setFilteredRows(response.data[responseField] || []);
          if (response.data.totalRows !== undefined) {
            setTotalRowCount(response.data.totalRows);
          }
        } catch (error) {
          console.error('Errore nel recupero dei dati:', error);
        }
      } else {
        // Ricerca locale quando apiConfig non è definito
        const localFilteredRows = rows.filter((row) => 
          !searchText || row[filterField]?.toLowerCase().includes(searchText.toLowerCase())
        );

        setFilteredRows(localFilteredRows);
        setTotalRowCount(localFilteredRows.length); 
      }
    };

    fetchData();
  }, [currentPage, pageSizeState, searchText, apiConfig, endpoint, queryParams, responseField, rows]);

  useEffect(() => {
    const startIndex = currentPage * pageSizeState;
    const endIndex = startIndex + pageSizeState;
    const paginated = filteredRows.slice(startIndex, endIndex);
    setPaginatedRows(paginated);
  }, [filteredRows, currentPage, pageSizeState]);

  const handlePageChange = (newPage) => setCurrentPage(newPage);
  const handlePageSizeChange = (newPageSize) => {
    setPageSizeState(newPageSize);
    setCurrentPage(0); // Reset alla prima pagina quando cambia il numero di righe per pagina
  };
  
  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(0); // Torna alla prima pagina
  };

  const handleReplayClick = (rowData) => console.log('Replay clicked for row:', rowData);
  const handleEditClick = (rowData) => console.log('Edit clicked for row:', rowData);

  const iconHandlers = {
    handleReplayClick,
    handleEditClick
  };

  const createLinkUrl = (urlTemplate, value) => {
    return urlTemplate.replace('{value}', value);
  };

  const customRenderCell = (params, render = {}, icons = [], avatarConfig = {}) => {
    const { fields = [params.field], linkField, linkUrl, linkFieldValue } = render;
    const startIcons = icons.filter((icon) => icon.position === "start");
    const endIcons = icons.filter((icon) => icon.position === "end");

    const renderIcon = (icon) => {
      const IconComponent = Icons[icon.type];
      const onClickHandler = iconHandlers[icon.onClick];

      return (
        IconComponent && (
          <IconButton
            key={icon.type}
            fontSize="small"
            sx={{ cursor: onClickHandler ? 'pointer' : 'default' }}
            onClick={() => onClickHandler && onClickHandler(params.row)}
          >
            <IconComponent />
          </IconButton>
        )
      );
    };

    const avatar = avatarConfig.urlField && params.row[avatarConfig.urlField] ? (
      <Avatar
        src={params.row[avatarConfig.urlField] || undefined} 
        sx={{ 
          width: avatarConfig.size || 40, 
          height: avatarConfig.size || 40, 
          marginRight: avatarConfig.position === "start" ? 1 : 0, 
          marginLeft: avatarConfig.position === "end" ? 1 : 0 
        }}
      />
    ) : (
      <Avatar
        sx={{ 
          width: avatarConfig.size || 40, 
          height: avatarConfig.size || 40, 
          backgroundColor: avatarConfig.backgroundColor || "gray", 
          marginRight: avatarConfig.position === "start" ? 1 : 0, 
          marginLeft: avatarConfig.position === "end" ? 1 : 0 
        }}
      >
        {params.row[avatarConfig.textField] || ""}
      </Avatar>
    );

    return (
      <Box display="flex" alignItems="center">
        {avatarConfig.position === "start" && avatar}
        {startIcons.map(renderIcon)}
        
        <Box>
          {fields.map((field, index) => {
            const fieldValue = params.row[field];
            const isLink = field === linkField;

            const linkValue = linkFieldValue ? params.row[linkFieldValue] : fieldValue;
            const url = isLink && linkUrl ? createLinkUrl(linkUrl, linkValue) : null;

            return isLink ? (
              <Link
                key={index}
                href={url}
                variant={index === 0 ? "body1" : "body2"}
                color="primary"
                underline="hover"
              >
                {fieldValue}
              </Link>
            ) : (
              <Typography
                key={index}
                variant={index === 0 ? "body1" : "body2"}
                color={index === 0 ? "textPrimary" : "textSecondary"}
              >
                {fieldValue}
              </Typography>
            );
          })}
        </Box>

        {endIcons.map(renderIcon)}
        {avatarConfig.position === "end" && avatar}
      </Box>
    );
  };

  const updatedColumns = columns.map((col) => {
    return {
      ...col,
      renderCell: (params) =>
        customRenderCell(params, col.render || {}, col.icons || [], col.avatar || {})
    };
  });

  return (
    <Card mb={6}>
      <CardHeader
        title={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">{gridTitle}</Typography>
            {filterField && (
              <TextField
                size="small"
                variant="outlined"
                placeholder="Cerca..."
                value={searchText}
                onChange={handleSearchChange}
                sx={{ marginLeft: 2 }}
              />
            )}
          </Box>
        }
      />
      <CardContent sx={{ "&:last-child": { padding: 0 } }}>
        <Paper>
          <div style={{ width: "100%", height: "100%" }}>
            <DataGrid
              rows={apiConfig ? filteredRows : paginatedRows}
              columns={updatedColumns}
              pageSize={pageSizeState}
              rowsPerPageOptions={rowsPerPageOptions || [5, 10, 20]}
              checkboxSelection={checkboxSelection}
              disableSelectionOnClick
              autoHeight
              rowCount={totalRowCount}
              paginationMode="server"
              page={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </Paper>
      </CardContent>
    </Card>
  );
}

export default DynamicDataGrid;
