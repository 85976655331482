import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { DataGrid, GridRowEditStopReasons } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  Card as MuiCard,
  Divider as MuiDivider,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { spacing } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import { useData } from "./useData";
import {
  useGetFleetGroupTableColumns,
  useGetFleetTableColumns,
} from "./columns";
import BarChart from "./BarChart";
import Filters from "./Filters";
import Modal from "./nuovaFlotta";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Flotta() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [filtersActive, setFiltersActive] = useState(isDesktop);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    getFleet,
    handleFleetPageChange,
    handlePageChange,
    handlePageSizeChange,
    handleApplyFilters,
    handleRemoveFilters,
    barChartData,
    rcData,
    totalRows,
    loading,
    filtersLoading,
    isApplyFiltersButtonDisabled,
    pageNumber,
    rowsPerPage,
    filterOptions,
    filterData,
    setFilterData,
    setFilterByDate,
    canClearFilters,
    isRemovingFilters,
    fleetData,
    fleetTotalRows,
    fleetPageNumber,
    setIdUidFlotta,
    idUidFlotta,
    setFleetName,
    fleetName,
  } = useData();
  const fleetColumns = useGetFleetGroupTableColumns(setIdUidFlotta);
  const columns = useGetFleetTableColumns();

  const switchFilterActive = () => {
    setFiltersActive((prev) => !prev);
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const handleSearchFleet = (event) => {
    setFleetName(event.target.value);
  };

  return (
    <React.Fragment>
      <Modal open={isModalOpen} setOpen={setIsModalOpen} getFleet={getFleet} />
      <Helmet title="RC Auto" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          Flotta
        </Typography>

        <Button variant="contained" onClick={() => setIsModalOpen(true)}>
          Carica Nuovo Flotta
        </Button>
      </Box>

      <Divider mt={4} />

      {loading ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container>
          {/* Charts */}
          <Grid
            container
            wrap="wrap"
            sx={{
              margin: isDesktop ? "0 -16px" : "0",
              padding: "0",
              width: isDesktop ? "calc(100% + 16px)" : "100%",
              marginTop: isDesktop ? "0" : "16px",
            }}
            spacing={isDesktop ? 4 : 0}
          >
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              sx={{ flex: 1, marginBottom: "16px", position: "relative" }}
            >
              <TextField
                label="Cerca flotta"
                variant="outlined"
                size="small"
                sx={{
                  width: isDesktop ? "200px" : isMobile ? "130px" : "180px",
                  position: "absolute",
                  left: isDesktop ? "32px" : "16px",
                  bottom: "19px",
                  zIndex: "10",
                }}
                onChange={handleSearchFleet}
                value={fleetName}
              />
              <Paper>
                <DataGrid
                  autoHeight
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  pagination
                  paginationMode="server"
                  rowCount={fleetTotalRows}
                  page={fleetPageNumber - 1}
                  onPageChange={(params) => handleFleetPageChange(params)}
                  getRowId={(usersList) => usersList.idUID}
                  rows={fleetData}
                  columns={fleetColumns}
                  editMode="row"
                  allowUpdating={true}
                  experimentalFeatures={{ newEditingApi: true }}
                  onRowEditStop={handleRowEditStop}
                  processRowUpdate={processRowUpdate}
                  disableColumnMenu
                  disableSelectionOnClick
                  getRowClassName={(params) =>
                    params.row.idUID === idUidFlotta
                      ? "MuiDataGrid-row--selected"
                      : ""
                  }
                  localeText={{ noRowsLabel: "Non ci sono risultati" }}
                  sx={{
                    minHeight: "392px",
                    "& .MuiDataGrid-row--selected": {
                      backgroundColor: "rgba(0, 0, 255, 0.1)",
                    },
                    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                      {
                        outline: "none",
                      },
                    "& .MuiDataGrid-footerContainer": {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                    },
                  }}
                />
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ flex: 1 }}>
              <BarChart
                barChartData={barChartData}
                filterOptions={filterOptions}
                setFilterByDate={setFilterByDate}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <>
              {Boolean(rcData.length) && (
                <Card sx={{ marginBottom: "1rem" }}>
                  <Filters
                    filtersActive={filtersActive}
                    switchFilterActive={switchFilterActive}
                    handleApplyFilters={handleApplyFilters}
                    handleRemoveFilters={handleRemoveFilters}
                    filtersLoading={filtersLoading}
                    isApplyFiltersButtonDisabled={isApplyFiltersButtonDisabled}
                    filterOptions={filterOptions}
                    filterData={filterData}
                    setFilterData={setFilterData}
                    canClearFilters={canClearFilters}
                    isRemovingFilters={isRemovingFilters}
                  />
                </Card>
              )}

              <Card mb={6}>
                <Paper>
                  <Box sx={{ width: "100%" }}>
                    <DataGrid
                      autoHeight
                      pageSize={rowsPerPage}
                      onPageSizeChange={handlePageSizeChange}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      paginationMode="server"
                      rowCount={totalRows}
                      page={pageNumber - 1}
                      onPageChange={(params) => handlePageChange(params)}
                      getRowId={(usersList) => usersList.idUID}
                      rows={rcData}
                      columns={columns}
                      editMode="row"
                      allowUpdating={true}
                      experimentalFeatures={{ newEditingApi: true }}
                      onRowEditStop={handleRowEditStop}
                      processRowUpdate={processRowUpdate}
                      disableColumnMenu
                      disableSelectionOnClick
                      localeText={{
                        noRowsLabel: idUidFlotta
                          ? "Non ci sono risultati"
                          : "Non e stata selezionata nessuna flotta",
                      }}
                      sx={{
                        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },
                        "& .MuiDataGrid-footerContainer": {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                        },
                      }}
                    />
                  </Box>
                </Paper>
              </Card>
            </>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default Flotta;
