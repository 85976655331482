// TabContent.js
import React from "react";
import { Grid } from "@mui/material";
import DynamicForm from "./DynamicForm";
import DynamicTable from "./DynamicTable";
import DynamicChart from "./DynamicChart";
import DynamicDataGrid from "./DynamicDataGrid";

function TabContent({ content }) {
  const renderElement = (element, index) => {
    switch (element.type) {
      case "form":
        return <DynamicForm key={index} formConfig={element.config} />;
      case "table":
        return <DynamicTable key={index} tableConfig={element.config} />;
      case "chart":
        return <DynamicChart key={index} chartConfig={element.config} />;
      case "datagrid":
        return <DynamicDataGrid key={index} gridConfig={element.config} />;
      // Aggiungi altri tipi di elementi se necessario
      default:
        return null;
    }
  };

  return (
    <Grid
      container
      direction={content.layout === "row" ? "row" : "column"}
      spacing={2}
      sx={{ marginBottom: 2 }}
      mt={0.5}
    >
      {content.sections.map((section, sectionIndex) => (
        <Grid
          container
          direction={section.layout === "row" ? "row" : "column"}
          spacing={2}
          key={sectionIndex}
          sx={{ marginBottom: 4 }}
        >
          {section.elements.map((element, index) => (
            <Grid
              item
              key={index}
              xs={element.grid.xs || 12}
              sm={element.grid.sm || 12}
              md={element.grid.md || 12}
              lg={element.grid.lg || 12}
              xl={element.grid.xl || 12}
            >
              {renderElement(element, index)}
            </Grid>
          ))}
        </Grid>
      ))}
    </Grid>
  );
}

export default TabContent;
