import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Box, Link, useMediaQuery, Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { Image, ExpandMore } from '@mui/icons-material';
import { Button, Divider } from '../style';

const theme = localStorage.getItem("theme");
const light = theme === '"LIGHT"' ? true : false;

const formatPrice = (price) => {
  return parseFloat(price).toLocaleString('it-IT', {
    style: 'currency',
    currency: 'EUR',
  });
};

const CardContainer = styled(Box)(({ theme, isExpanded }) => ({
  width: '100%',
  marginBottom: theme.spacing(3),
  borderTop: light ? "1px solid #0000001f" : "1px solid #ffffff1f",
  borderBottom: light ? "1px solid #0000001f" : "1px solid #ffffff1f",
  borderLeft: "2px solid transparent",
  borderRight: "2px solid transparent",
  transition: 'all .3s linear',
  borderRadius: 0,
  height: "auto",
  overflow: 'hidden',
  '&:hover': {
    border: "2px solid #FE6A00",
  },
}));

const FullWidthCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: 0,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
  position: 'relative',  
}));

const KeyValueRow = styled(Box)(({ theme, isEven }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  backgroundColor: isEven ? "rgba(255,255,255,.05)" : 'transparent',
}));

const SmallButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#FE6A00',
  color: 'white',
  fontSize: '0.75rem',
  borderRadius: 0,
  padding: theme.spacing(0.5, 1),
  '&:hover': {
    backgroundColor: '#e55d00',
  },
}));

const ListComponent = ({ dataset }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentType, setContentType] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container direction="column">
      {dataset && dataset.map((item) => {

        const handleExpandWithTable = () => {
          setIsExpanded(!isExpanded);
          setContentType('table');
        };

        const handleExpandWithList = () => {
          setIsExpanded(!isExpanded);
          setContentType('list');
        };

        return (
          <Grid item key={item.idUID}>
            <CardContainer isExpanded={isExpanded}>
              <FullWidthCard>

                

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  pt={2}
                  sx={{
                    flexBasis: isMobile ? '100%' : '25%',
                    textAlign: 'center',
                  }}
                >
                  <Image
                    alt={item.Compagnia}
                    src={item.logo || ''}
                    sx={{
                      width: 80,
                      height: 80,
                      bgcolor: 'gray',
                      marginBottom: 1,
                    }}
                  />
                </Box>

                <CardContent sx={{
                  flexGrow: 1,
                  padding: 2,
                  flexBasis: isMobile ? '100%' : '40%',
                }}>
                  <Typography variant="h1" color="#FE6A00" fontWeight="bold" sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                    {formatPrice(item.Prezzo_Totale)}
                  </Typography>
                  <Divider my={3} />
                  {Object.entries(item).map(([key, value], idx) => (
                    key !== 'idUID' && key !== 'Prezzo_Totale' && key !== 'logo' && (
                      <KeyValueRow key={key} isEven={idx % 2 === 0}>
                        <Typography variant="body2" color="textPrimary" fontWeight="bold">
                          {key}:
                        </Typography>
                        <Typography variant="body2" color="textPrimary" textAlign="end">
                          {value || '-'}
                        </Typography>
                      </KeyValueRow>
                    )
                  ))}

                  
                </CardContent>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  sx={{
                    flexBasis: isMobile ? '100%' : '15%',
                    textAlign: 'center',
                    justifyContent: 'flex-start', 
                    paddingTop: 2, 
                  }}
                >
                  <Button sx={{ background: "#5cb85c", py: 1, color: "white", width: "80%", fontSize: '0.8rem' }}>
                    VAI
                  </Button>
                </Box>
              </FullWidthCard>

              <Box display="flex" justifyContent="flex-start" p={2}>
                <SmallButton onClick={handleExpandWithTable} sx={{ mr: 2 }}>
                  <ExpandMore sx={{ transform: isExpanded && contentType === 'table' ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
                  {isExpanded && contentType === 'table' ? 'Riduci' : 'Espandi con Tabella'}
                </SmallButton>

                <SmallButton onClick={handleExpandWithList}>
                  <ExpandMore sx={{ transform: isExpanded && contentType === 'list' ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} />
                  {isExpanded && contentType === 'list' ? 'Riduci' : 'Espandi con Elenco'}
                </SmallButton>
              </Box>

              {isExpanded && (
                <Box p={2}>
                  {contentType === 'table' && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Colonna 1</TableCell>
                          <TableCell>Colonna 2</TableCell>
                          <TableCell>Colonna 3</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Dato 1</TableCell>
                          <TableCell>Dato 2</TableCell>
                          <TableCell>Dato 3</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Dato 4</TableCell>
                          <TableCell>Dato 5</TableCell>
                          <TableCell>Dato 6</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  {contentType === 'list' && (
                    <ul>
                      <li>Elemento 1</li>
                      <li>Elemento 2</li>
                      <li>Elemento 3</li>
                    </ul>
                  )}
                </Box>
              )}

              
              
            </CardContainer>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ListComponent;
