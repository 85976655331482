import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import styled from "@emotion/styled";
import {
  ArrowDown,
  ArrowUp,
  ArrowDownCircle,
  ArrowUpCircle,
  Minus,
} from "react-feather";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Sintesi = ({ report, ref }) => {
  const is425px = useMediaQuery("(max-width:425px)");
  const numberOfEmployees = report.otherInformation === "" ? "" : "n.d";

  const CreditLimitValue = () => {
    const prevValue = report.creditScore.previousCreditRating.creditLimit.value;
    const currValue = report.creditScore.currentCreditRating.creditLimit.value;
    const strValue =
      prevValue !== currValue
        ? "Limite di credito cambiato"
        : "Limite di credito invariato";

    return <Typography>{strValue}</Typography>;
  };

  const ScoreValue = () => {
    const prevValue =
      report.creditScore.previousCreditRating.providerValue.value;
    const currValue =
      report.creditScore.currentCreditRating.providerValue.value;
    const strValue =
      prevValue !== currValue ? "Score cambiato" : "Score invariato";

    return <Typography>{strValue}</Typography>;
  };

  return (
    <Box ref={ref}>
      <Grid container spacing={4} mt={0.5}>
        <Grid item md={6} sm={12} xs={12} pt={0}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">Informazioni principali</Typography>
              </Box>
              <Box display="flex" width="100%" gap={2} flexDirection="column">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Ragione sociale:</Typography>
                  <Typography>
                    {report.alternateSummary.businessName}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Partita IVA:</Typography>
                  <Typography>
                    {report.alternateSummary.vatRegistrationNumber}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">CCIAA/REA:</Typography>
                  <Typography>
                    {report.alternateSummary.companyRegistrationNumber}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">
                    Data di costituzione:
                  </Typography>
                  <Typography>
                    {new Date(
                      report.alternateSummary.incorporationDate
                    ).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Forma giuridica:</Typography>
                  <Typography>{report.alternateSummary.legalForm}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Codice Fiscale:</Typography>
                  <Typography>{report.alternateSummary.taxCode}</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">
                  Informazioni di Contatto
                </Typography>
              </Box>

              <Box display="flex" width="100%" gap={2} flexDirection="column">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Indirizzo:</Typography>
                  <Typography>{report.alternateSummary.address}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Numero di telefono:</Typography>
                  <Typography>{report.alternateSummary.telephone}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexDirection={is425px ? "column" : "row"}
                >
                  <Typography fontWeight="600">Indirizzo mail PEC:</Typography>
                  <Typography>
                    {report.alternateSummary.emailAddresses}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">Informazioni Secondarie</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={8}
                flexDirection={is425px ? "column" : "row"}
              >
                <Box display="flex" width="100%" gap={2} flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Data iscrizione REA:
                    </Typography>
                    <Typography>
                      {new Date(
                        report.alternateSummary.reaInscriptionDate
                      ).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Data Ultimo Protocollo:
                    </Typography>
                    <Typography>
                      {new Date(
                        report.alternateSummary.latestUpdateOnIc
                      ).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Capitale Sociale:</Typography>
                    <Typography>
                      {new Intl.NumberFormat("it-IT", {
                        style: "currency",
                        currency: "EUR",
                      }).format(report.alternateSummary.shareCapital)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Numero Medio Dipendenti:
                    </Typography>
                    <Typography>{numberOfEmployees}</Typography>
                  </Box>
                </Box>

                <Box display="flex" width="100%" gap={2} flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Stato Società:</Typography>
                    <Typography>
                      {report.alternateSummary.companyStatus.status}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Sezione Registro Imprese:
                    </Typography>
                    <Typography>
                      {report.alternateSummary.publicRegisterSection}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Tipo Sede:</Typography>
                    <Typography>{report.alternateSummary.hqType}</Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">
                  Informazioni Settore Industriale
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                gap={8}
                flexDirection={is425px ? "column" : "row"}
              >
                <Box display="flex" width="100%" gap={2} flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Codice ateco 2007:</Typography>
                    <Typography>
                      {report.companySummary.mainActivity.code}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Descrizione ateco 2007:
                    </Typography>
                    <Typography textAlign={is425px ? "left" : "right"}>
                      {report.companySummary.mainActivity?.description}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Codice SAE:</Typography>
                    <Typography>{report.alternateSummary.saeCode}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      Descrizione Attività Svolta:
                    </Typography>
                    <Typography textAlign={is425px ? "left" : "right"}>
                      {
                        report.alternateSummary.activityClassifications
                          ?.description
                      }
                    </Typography>
                  </Box>
                </Box>

                <Box display="flex" width="100%" gap={2} flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">
                      N° di Società nel macro:
                    </Typography>
                    <Typography>
                      {report.alternateSummary.numberOfCompaniesInActivityCode}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Di cui cancellate:</Typography>
                    <Typography>
                      {
                        report.alternateSummary
                          .numberOfCancelledCompaniesInActivityCode
                      }
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection={is425px ? "column" : "row"}
                    gap={is425px ? 0 : 4}
                  >
                    <Typography fontWeight="600">Codice RAE:</Typography>
                    <Typography>{report.alternateSummary.raeCode}</Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {report.financialStatements && (
          <Grid item md={12} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Box marginBottom={4}>
                  <Typography fontSize="1rem">
                    Dati Finanziari Chiave
                  </Typography>
                </Box>
                <TableWrapper>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        {report.financialStatements?.map((statement) => {
                          const yearEndDate = new Date(
                            statement.yearEndDate
                          ).toLocaleDateString();
                          return (
                            <TableCell align="center" key={yearEndDate}>
                              {yearEndDate}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography textAlign="center">
                            Totale valore della produzione
                          </Typography>
                        </TableCell>
                        {report.financialStatements?.map((statement, i) => {
                          const revenue = statement.profitAndLoss?.revenue
                            ?.toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");

                          const revenueNum = statement.profitAndLoss?.revenue;
                          const nextStatement =
                            report.financialStatements[i + 1];
                          const revenueNumNext =
                            nextStatement?.profitAndLoss?.revenue;

                          const formatNumber = (num) =>
                            parseFloat(num?.toString().replace(/,/g, "") || 0); // Handle number formatting

                          function calculatePercentageDifference() {
                            if (revenueNum == null || revenueNumNext == null)
                              return ""; // Handle missing data
                            if (i + 1 < report.financialStatements?.length) {
                              const currentRevenue = formatNumber(revenueNum);
                              const previousRevenue =
                                formatNumber(revenueNumNext);

                              const percentageDifference = Math.abs(
                                Math.ceil(
                                  (1 - currentRevenue / previousRevenue) * 100
                                )
                              );

                              if (revenueNum > revenueNumNext) {
                                return (
                                  <Box
                                    textAlign="center"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    color="#4dbb81"
                                  >
                                    <ArrowUp size={10} />
                                    <Typography>
                                      {percentageDifference}%
                                    </Typography>
                                  </Box>
                                );
                              } else {
                                return (
                                  <Box
                                    textAlign="center"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    color="#ee2e24"
                                  >
                                    <ArrowDown size={10} />
                                    <Typography>
                                      {percentageDifference}%
                                    </Typography>
                                  </Box>
                                );
                              }
                            }
                          }

                          return (
                            <TableCell width="20%" key={i}>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  textAlign="center"
                                  display="flex"
                                  justifyContent="center"
                                  gap={2}
                                >
                                  {revenue}

                                  {calculatePercentageDifference()}
                                </Box>
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography textAlign="center">
                            Utile (Perdita) dell'esercizio
                          </Typography>
                        </TableCell>

                        {report.financialStatements?.map((statement, i) => {
                          const profit = statement.profitAndLoss?.profitAfterTax
                            ?.toString()
                            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");

                          const profitNum =
                            statement.profitAndLoss?.profitAfterTax;
                          const nextStatement =
                            report.financialStatements[i + 1];
                          const profitNumNext =
                            nextStatement?.profitAndLoss?.profitAfterTax;

                          const formatNumber = (num) =>
                            parseFloat(num?.toString().replace(/,/g, "") || 0); // Handle number formatting

                          function calculatePercentageDifference() {
                            if (profitNum == null || profitNumNext == null)
                              return ""; // Handle missing data

                            if (i + 1 < report.financialStatements?.length) {
                              const currentProfit = formatNumber(profitNum);
                              const previousProfit =
                                formatNumber(profitNumNext);

                              const percentageDifference = Math.abs(
                                Math.ceil(
                                  (1 - currentProfit / previousProfit) * 100
                                )
                              );

                              if (profitNum > profitNumNext) {
                                return (
                                  <Box
                                    textAlign="center"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    color="#4dbb81"
                                  >
                                    <ArrowUp size={10} />
                                    <Typography>
                                      {percentageDifference}%
                                    </Typography>
                                  </Box>
                                );
                              } else {
                                return (
                                  <Box
                                    textAlign="center"
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    color="#ee2e24"
                                  >
                                    <ArrowDown size={10} />
                                    <Typography>
                                      {percentageDifference}%
                                    </Typography>
                                  </Box>
                                );
                              }
                            }
                          }

                          return (
                            <TableCell width="20%" key={i}>
                              <Box display="flex" flexDirection="column">
                                <Box
                                  textAlign="center"
                                  display="flex"
                                  justifyContent="center"
                                  gap={2}
                                >
                                  {profit}

                                  {calculatePercentageDifference()}
                                </Box>
                              </Box>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableWrapper>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item md={12} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">Commenti</Typography>
              </Box>
              <Box display="flex" width="100%" gap={2} flexDirection="column">
                {report.alternateSummary.commentaries?.map((commentary, i) => {
                  return (
                    <Box display="flex" gap={2} key={i}>
                      {commentary.positiveOrNegative === "Positive" ? (
                        <Box
                          display="flex"
                          flexDirection={is425px ? "column" : "row"}
                          alignItems={is425px ? "start" : "center"}
                          gap={2}
                        >
                          <ArrowUpCircle size="16" color="#4dbb81" />
                          <Typography>{commentary.commentaryText}</Typography>
                        </Box>
                      ) : commentary.positiveOrNegative === "Negative" ? (
                        <Box
                          display="flex"
                          flexDirection={is425px ? "column" : "row"}
                          gap={2}
                          alignItems={is425px ? "start" : "center"}
                        >
                          <ArrowDownCircle size="16" color="#ee2e24" />
                          <Typography>{commentary.commentaryText}</Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          flexDirection={is425px ? "column" : "row"}
                          gap={2}
                          alignItems={is425px ? "start" : "center"}
                        >
                          <Minus size="16" />
                          <Typography>{commentary.commentaryText}</Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Box marginBottom={4}>
                <Typography fontSize="1rem">Storico Eventi</Typography>
              </Box>
              <TableWrapper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data Evento</TableCell>
                      <TableCell>Descrizione</TableCell>
                      <TableCell>Valore precedente</TableCell>
                      <TableCell>Valore attuale</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {new Date(
                          report.alternateSummary.latestUpdateOnIc
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{CreditLimitValue()}</TableCell>
                      <TableCell>
                        {report.creditScore.previousCreditRating.creditLimit.value
                          ?.toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}
                      </TableCell>
                      <TableCell>
                        {report.creditScore.currentCreditRating.creditLimit.value
                          ?.toString()
                          .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".")}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {new Date(
                          report.alternateSummary.latestUpdateOnIc
                        ).toLocaleDateString()}
                      </TableCell>
                      <TableCell>{ScoreValue()}</TableCell>
                      <TableCell>
                        {
                          report.creditScore.previousCreditRating.providerValue
                            .value
                        }
                      </TableCell>
                      <TableCell>
                        {
                          report.creditScore.currentCreditRating.providerValue
                            .value
                        }
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableWrapper>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Sintesi;
