import React from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@mui/material";

function CustomSelect({
  disabled,
  name,
  value,
  onChange,
  error,
  helperText,
  sx = {},
  options,
  label,
  MenuProps,
  fullWidth = true,
  required,
  size = true, // Added size prop
}) {
  // Add a style object for the size
  const sizeStyles =
    size === "small"
      ? {
          "& .MuiInputBase-root": {
            height: "36px",
          },
          "& .MuiSelect-select": {
            paddingTop: "10px",
            paddingBottom: "10px",
            boxSizing: "border-box",
          },
          "& .MuiInputLabel-root": {
            transform: "translate(14px, 9px) scale(1)", // Adjust label position for small size
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)", // Adjust label position when shrink
          },
        }
      : {};

  return (
    <FormControl
      size="small"
      fullWidth={fullWidth}
      disabled={disabled}
      error={Boolean(error)}
      sx={{ ...sx, ...sizeStyles }} // Apply the size styles
      variant="outlined" // Ensure the variant is set to outlined
    >
      {label && (
        <InputLabel id={`${name}-label`}>
          {required ? `${label} *` : label}
        </InputLabel>
      )}
      <Select
        labelId={`${name}-label`}
        label={label} // Ensure label prop is set
        fullWidth={fullWidth}
        name={name}
        onChange={onChange}
        value={value}
        MenuProps={MenuProps}
        sx={sizeStyles} // Apply the size styles to Select
      >
        {options?.map((option, index) => {
          const isOptionObject = typeof option === "object" && option !== null;
          const optionValue = isOptionObject ? option.value : option;
          const optionLabel = isOptionObject ? option.label : option;

          return (
            <MenuItem key={index} value={optionValue}>
              {optionLabel}
            </MenuItem>
          );
        })}
      </Select>
      {helperText && (
        <FormHelperText error={Boolean(error)}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}

export default CustomSelect;
