// Themes
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
};

export const rl = {
  "64DB0E0C-98FF-4837-9E4E-E0D501ED616E": "supAdm",
  "05944AC9-BF1F-4F74-8019-316DD44A3D19": "adm",
  "4A340D1B-6420-4295-8575-5D63EDFA59DE": "supUsr",
  "1DD0CFF2-EE9E-4076-B2B3-38BE92D9F423": "usr",
  "AB04C711-020C-4D45-855C-4A0BE5AAEAC7": "cor",
};
