import React from "react";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import useTheme from "../../hooks/useTheme";
import { THEMES } from "../../constants";
import { IconButton, Tooltip } from "@mui/material";

function NavbarThemeDropdown() {
  const { theme, setTheme } = useTheme();
  const IconStyle = { cursor: "pointer" };

  const handleThemeSwitch = () => {
    const variant = theme === THEMES.LIGHT ? THEMES.DARK : THEMES.LIGHT;
    setTheme(variant);
  };

  return (
    <React.Fragment>
      <Tooltip title="Theme">
        <IconButton color="inherit" onClick={handleThemeSwitch} size="large">
          {theme === THEMES.LIGHT ? (
            <DarkModeIcon size="large" sx={IconStyle} color="inherit" />
          ) : (
            <LightModeIcon size="large" sx={IconStyle} color="inherit" />
          )}
        </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarThemeDropdown;
