import {
  Box,
  Card,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
  DialogActions,
} from "@mui/material";
import React, {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import Sintesi from "./Sintesi";
import Score from "./Score";
import Bilanci from "./Bilanci";

import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import { Download } from "react-feather";
import Riclassificato from "./bilanci/Riclassificato";
import IFRS from "./bilanci/IFRS";

const Divider = styled(MuiDivider)(spacing);

const CustomTabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case "header": {
      return {
        data: {
          companyId: action.value.companyId,
          correlationId: action.value.correlationId,
          dataOfOrder: action.value.dateOfOrder,
          language: action.value.language,
          report: {
            alternateSummary: {
              activityClassifications: {
                description:
                  action.value.report.alternateSummary.activityClassifications
                    ?.description,
              },
              address: action.value.report.alternateSummary?.address,
              businessName: action.value.report.alternateSummary?.businessName,
              closureDate: action.value.report.alternateSummary?.closureDate,
              commentaries: action.value.report.alternateSummary?.commentaries, //DA FARE
              companyNumber:
                action.value.report.alternateSummary?.companyNumber,
              companyRegistrationNumber:
                action.value.report.alternateSummary?.companyRegistrationNumber,
              companyStatus: {
                description:
                  action.value.report.alternateSummary?.companyStatus
                    .description,
                status:
                  action.value.report.alternateSummary?.companyStatus.status,
              },
              contactAddress: {
                city: action.value.report.alternateSummary?.contactAddress.city,
                country:
                  action.value.report.alternateSummary?.contactAddress.country,
                houseNumber:
                  action.value.report.alternateSummary?.contactAddress
                    .houseNumber,
                postalCode:
                  action.value.report.alternateSummary?.contactAddress
                    .postalCode,
                province:
                  action.value.report.alternateSummary?.contactAddress.province,
                simpleValue:
                  action.value.report.alternateSummary?.contactAddress
                    .simpleValue,
                street:
                  action.value.report.alternateSummary?.contactAddress.street,
              },
              country: action.value.report.alternateSummary?.country,
              emailAddresses:
                action.value.report.alternateSummary?.emailAddresses,
              hqType: action.value.report.alternateSummary?.hqType,
              incorporationDate:
                action.value.report.alternateSummary?.incorporationDate,
              latestTurnoverFigure: {
                currency:
                  action.value.report.alternateSummary.latestTurnoverFigure
                    ?.currency,
                value:
                  action.value.report.alternateSummary.latestTurnoverFigure
                    ?.value,
              },
              latestUpdateOnIc:
                action.value.report.alternateSummary?.latestUpdateOnIc,
              legalForm: action.value.report.alternateSummary?.legalForm,
              legalFormCode:
                action.value.report.alternateSummary?.legalFormCode,
              legalFormType:
                action.value.report.alternateSummary?.legalFormType,
              mainActivity: {
                code: action.value.report.alternateSummary?.mainActivity.code,
                description:
                  action.value.report.alternateSummary?.mainActivity
                    .description,
              },
              numberOfCancelledCompaniesInActivityCode:
                action.value.report.alternateSummary
                  ?.numberOfCancelledCompaniesInActivityCode,
              numberOfCompaniesInActivityCode:
                action.value.report.alternateSummary
                  ?.numberOfCompaniesInActivityCode,
              numberOfEmployees:
                action.value.report.alternateSummary?.numberOfEmployees,
              province: action.value.report.alternateSummary?.province,
              publicRegisterSection:
                action.value.report.alternateSummary?.publicRegisterSection,
              raeCode: action.value.report.alternateSummary?.raeCode,
              reaInscriptionDate:
                action.value.report.alternateSummary?.reaInscriptionDate,
              registerStatus:
                action.value.report.alternateSummary?.registerStatus,
              saeCode: action.value.report.alternateSummary?.saeCode,
              shareCapital: action.value.report.alternateSummary?.shareCapital,
              taxCode: action.value.report.alternateSummary?.taxCode,
              telephone: action.value.report.alternateSummary?.telephone,
              vatRegistrationNumber:
                action.value.report.alternateSummary?.vatRegistrationNumber,
            },
            companyId: action.value.report.companyId,
            companySummary: {
              businessName: action.value.report.companySummary?.businessName,
              companyNumber: action.value.report.companySummary?.companyNumber,
              companyRegistrationNumber:
                action.value.report.companySummary?.companyRegistrationNumber,
              companyStatus: {
                description:
                  action.value.report.companySummary?.companyStatus.description,
                status:
                  action.value.report.companySummary?.companyStatus.status,
              },
              country: action.value.report.companySummary?.country,
              creditRating: {
                commonDescription:
                  action.value.report.companySummary?.creditRating
                    .commonDescription,
                commonValue:
                  action.value.report.companySummary?.creditRating.commonValue,
                creditLimit: {
                  currency:
                    action.value.report.companySummary.creditRating.creditLimit
                      .currency,
                  value:
                    action.value.report.companySummary.creditRating.creditLimit
                      .value,
                },
                providerDescription:
                  action.value.report.companySummary.creditRating
                    .providerDescription,
                providerValue: {
                  maxValue:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.maxValue,
                  minValue:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.minValue,
                  value:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.value,
                },
              },
              ggsId: action.value.report.companySummary?.ggsId,
              latestShareholdersEquityFigure: {
                currency:
                  action.value.report.companySummary
                    .latestShareholdersEquityFigure?.currency,
                value:
                  action.value.report.companySummary
                    .latestShareholdersEquityFigure?.value,
              },
              latestTurnoverFigure: {
                currency:
                  action.value.report.companySummary.latestTurnoverFigure
                    ?.currency,
                value:
                  action.value.report.companySummary.latestTurnoverFigure
                    ?.value,
              },
              mainActivity: {
                classification:
                  action.value.report.companySummary.mainActivity
                    ?.classification,
                code: action.value.report.companySummary.mainActivity?.code,
                description:
                  action.value.report.companySummary.mainActivity?.description,
              },
            },
            companySummaryExtra: {
              hasCigsEvents:
                action.value.report.companySummaryExtra?.hasCigsEvents,
              hasCompaniesInsolvency:
                action.value.report.companySummaryExtra?.hasCompaniesInsolvency,
              hasInsolvency:
                action.value.report.companySummaryExtra?.hasInsolvency,
              hasPrejudicials:
                action.value.report.companySummaryExtra?.hasPrejudicials,
              hasProtesti: action.value.report.companySummaryExtra?.hasProtesti,
              hasSeverePrejudicials:
                action.value.report.companySummaryExtra?.hasSeverePrejudicials,
              hasSevereProtesti:
                action.value.report.companySummaryExtra?.hasSevereProtesti,
            },
            creditScore: {
              currentCreditRating: {
                commonDescription:
                  action.value.report.creditScore.currentCreditRating
                    ?.commonDescription,
                commonValue:
                  action.value.report.creditScore.currentCreditRating
                    ?.commonValue,
                creditLimit: {
                  currency:
                    action.value.report.creditScore.currentCreditRating
                      ?.creditLimit.currency,
                  value:
                    action.value.report.creditScore.currentCreditRating
                      ?.creditLimit.value,
                },
                providerDescription:
                  action.value.report.creditScore.currentCreditRating
                    ?.providerDescription,
                providerValue: {
                  maxValue:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.maxValue,
                  minValue:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.minValue,
                  value:
                    action.value.report.creditScore.currentCreditRating
                      .providerValue.value,
                },
              },
              latestRatingChangeDate:
                action.value.report.creditScore.latestRatingChangeDate,
              previousCreditRating: {
                commonDescription:
                  action.value.report.creditScore.previousCreditRating
                    ?.commonDescription,
                commonValue:
                  action.value.report.creditScore.previousCreditRating
                    ?.commonValue,
                creditLimit: {
                  currency:
                    action.value.report.creditScore.previousCreditRating
                      .creditLimit?.currency,
                  value:
                    action.value.report.creditScore.previousCreditRating
                      .creditLimit?.value,
                },
                providerDescription:
                  action.value.report.creditScore.previousCreditRating
                    .providerDescription,
                providerValue: {
                  maxValue:
                    action.value.report.creditScore.previousCreditRating
                      .providerValue.maxValue,
                  minValue:
                    action.value.report.creditScore.previousCreditRating
                      .providerValue.minValue,
                  value:
                    action.value.report.creditScore.previousCreditRating
                      .providerValue.value,
                },
              },
            },
            financialStatements: action.value.report?.financialStatements,
            language: action.value.report?.language,
            localFinancialStatements:
              action.value.report.localFinancialStatements,
            localIndicators: action.value.report?.localIndicators,
            otherInformation: {
              employeesInformation:
                action.value.report.otherInformation?.employeesInformation, // Da fare
            },
          },
          userId: action.value.userId,
        },
      };
    }
  }
  throw Error("Unknown action: " + action.type);
}

const initialState = {
  data: {
    companyId: "",
    correlationId: "",
    dataOfOrder: "",
    language: "",
    report: {
      alternateSummary: {
        activityClssifications: {
          description: "",
        },
        address: "",
        businessName: "",
        closureDate: "",
        commentaries: [],
        companyNumber: "",
        companyRegistrationNumber: "",
        companyStatus: {
          description: "",
          status: "",
        },
        contactAddress: {
          city: "",
          country: "",
          houseNumber: "",
          postalCode: "",
          province: "",
          simpleValue: "",
          street: "",
        },
        country: "",
        emailAddresses: "",
        hqType: "",
        incorporationDate: "",
        latestTurnoverFigure: {
          currency: "",
          value: 0,
        },
        latestUpdateOnIc: "",
        legalForm: "",
        legalFormCode: "",
        legalFormType: "",
        mainActivity: {
          code: "",
          description: "",
        },
        numberOfCancelledCompaniesInActivityCode: 0,
        numberOfCompaniesInActivityCode: 0,
        numberOfEmployees: "",
        province: "",
        publicRegisterSection: "",
        raeCode: "",
        reaInscriptionDate: "",
        registerStatus: "",
        saeCode: "",
        shareCapital: 0,
        taxCode: "",
        telephone: "",
        vatRegistrationNumber: "",
      },
      companyId: "",
      companySummary: {
        businessName: "",
        companyNumber: "",
        companyRegistrationNumber: "",
        companyStatus: {
          description: "",
          status: "",
        },
        country: "",
        creditRating: {
          commonDescription: "",
          commonValue: "",
          creditLimit: {
            currency: "",
            value: "",
          },
          providerDescription: "",
          providerValue: {
            maxValue: "",
            minValue: "",
            value: "",
          },
        },
        ggsId: "",
        latestShareholdersEquityFigure: {
          currency: "",
          value: 0,
        },
        latestTurnoverFigure: {
          currency: "",
          value: 0,
        },
        mainActivity: {
          classification: "",
          code: "",
          description: "",
        },
      },
      companySummaryExtra: {
        hasCigsEvents: false,
        hasCompaniesInsolvency: false,
        hasInsolvency: false,
        hasPrejudicials: false,
        hasProtesti: false,
        hasSeverePrejudicials: false,
        hasSevereProtesti: false,
      },
      creditScore: {
        currentCreditRating: {
          commonDescription: "",
          commonValue: "",
          creditLimit: {
            currency: "",
            value: "",
          },
          providerDescription: "",
          providerValue: {
            maxValue: "",
            minValue: "",
            value: "",
          },
        },
        latestRatingChangeDate: "",
        previousCreditRating: {
          commonDescription: "",
          commonValue: "",
          creditLimit: {
            currency: "",
            value: "",
          },
          providerDescription: "",
          providerValue: {
            maxValue: "",
            minValue: "",
            value: "",
          },
        },
      },
      financialStatements: [],
      language: "",
      localFinancialStatements: [],
      localIndicators: [],
      otherInformation: {
        employeesInformation: [],
      },
    },
    userId: "",
  },
};

const Visuredetails = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const dataJSON = useRef([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:425px)");
  const is768px = useMediaQuery("(min-width:768px)");
  const is1024px = useMediaQuery("(max-width:1024px)");
  const [valueTabs, setValueTabs] = useState(0);
  const flag = state.data.report.companySummary.country;
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [valueTabsBilanci, setValueTabsBilanci] = useState(0);

  const handleChangeTabsBilanci = (event, newValue) => {
    setValueTabsBilanci(newValue);
  };

  const { idUID } = useParams();

  async function visureGetDetails() {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
          idUidAzienda: idUID,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/visureGetDetails",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === 0) {
        sessionStorage.clear();
        navigate("/private");
      }
      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/pages/visure");
      }
      dataJSON.current = responseData.data;
      dispatch({
        type: "header",
        value: dataJSON.current,
      });
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    visureGetDetails().finally(() => setRefresh(false));
  }, []);

  const SummaryReport = () => {
    const scoreRisk =
      state.data.report.creditScore.currentCreditRating.providerValue.value;

    const creditLimitValue =
      state.data.report.creditScore.currentCreditRating.creditLimit.value;

    const status = state.data.report.companySummary.companyStatus.status;

    const hasProtesti = state.data.report.companySummaryExtra.hasProtesti;

    const hasSevereProtesti =
      state.data.report.companySummaryExtra.hasSevereProtesti;

    const borderBottomColorScoreRisk = () => {
      if (scoreRisk <= 20 || scoreRisk === null || isNaN(scoreRisk)) {
        return "4px solid #ee2e24"; //Risk High
      } else if (scoreRisk >= 21 && scoreRisk <= 29) {
        return "4px solid #f7941d"; //Risk Medium High
      } else if (scoreRisk >= 30 && scoreRisk <= 50) {
        return "4px solid #f3c534"; //Risk Medium
      } else if (scoreRisk >= 51 && scoreRisk <= 70) {
        return "4px solid #6dc797"; //Risk Medium Low
      } else if (scoreRisk >= 71) {
        return "4px solid #409a6a"; //Risk Low
      }
    };

    const borderBottomColorCreditLimitValue = () => {
      if (creditLimitValue <= 0) {
        return "4px solid #ee2e24";
      } else if (creditLimitValue >= 0 && creditLimitValue <= 10000) {
        return "4px solid #6dc797";
      } else return "4px solid #409a6a";
    };

    return (
      <Grid
        container
        spacing={6} /* sx={{ display: "flex", gap: 8, flexWrap: "wrap" }} */
      >
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{ borderBottom: borderBottomColorScoreRisk, height: "100%" }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                alignContent: "end",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Punteggio di Rischio
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize="1.5rem"
              >
                {scoreRisk}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{ borderBottom: borderBottomColorScoreRisk, height: "100%" }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Score Internazionale
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize="1.5rem"
              >
                {state.data.report.companySummary.creditRating.commonValue}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{
              borderBottom: borderBottomColorCreditLimitValue,
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Credito
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize={
                  is768px && is1024px && creditLimitValue >= 1000
                    ? "1rem"
                    : "1.5rem"
                }
              >
                {new Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                }).format(creditLimitValue)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{
              borderBottom:
                status === "Active" ? "4px solid #409a6a" : "4px solid #f3c534",
              height: "100%",
            }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Stato
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize="1.5rem"
              >
                {status === "Active" ? "Attivo" : "Non Attivo"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{ borderBottom: borderBottomColorScoreRisk, height: "100%" }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Protesti
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize="1.5rem"
              >
                {hasProtesti ? "SI" : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={2} md={2} sm={4} xs={12}>
          <Card
            sx={{ borderBottom: borderBottomColorScoreRisk, height: "100%" }}
          >
            <CardContent
              sx={{
                mx: 4,
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                mb={6}
                textAlign="center"
                fontSize="0.8rem"
                fontWeight={100}
              >
                Stato Protesti
              </Typography>
              <Typography
                variant="h3"
                mb={2}
                textAlign="center"
                fontSize="1.5rem"
              >
                {hasSevereProtesti ? "SI" : "-"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  const [currentIndex, setCurrentIndex] = useState(0); // Index in tabsToInclude array
  const [currentIndexBilanci, setCurrentIndexBilanci] = useState(0); // Index in tabsToInclude array
  const [generatingPDF, setGeneratingPDF] = useState(false); // Flag for PDF generation
  const [printAllTabs, setPrintAllTabs] = useState(false); // Condition to control number of tabs in PDF
  const tabContentRefs = [useRef(), useRef(), useRef(), useRef()]; // References to tab contents
  const bilanciRefs = [useRef(), useRef(), useRef()]; // References to tab contents
  const tempContainer = useRef(document.createElement("div")); // Temporary container
  const [refresh, setRefresh] = useState(true); // Condition to control number of tabs in PDF

  const generatePDF = (bool) => {
    setPrintAllTabs(bool); // Set the flag for which tabs to include
    setGeneratingPDF(true);
    setCurrentIndex(0); // Start from the first item in tabsToInclude
  };

  // Effect to generate PDF, iterates only over selected tabs
  useEffect(() => {
    tempContainer.current.style.backgroundColor =
      theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC";
    if (printAllTabs === true) {
      if (currentIndex === 0) {
        const tabRef = tabContentRefs[0].current;
        if (tabRef) tempContainer.current.appendChild(tabRef.cloneNode(true));
      }
      const tabsToInclude = [1, 2, 3]; // Only include the selected tabs
      const currentTabToProcess = tabsToInclude[currentIndex]; // Determine which tab to process

      if (generatingPDF && currentIndex < tabsToInclude.length) {
        if (
          currentTabToProcess === 2 &&
          currentIndexBilanci < bilanciRefs.length
        ) {
          // If on "bilanci" tab, process each sub-tab in `bilanciRefs`
          const bilanciTabRef = bilanciRefs[currentIndexBilanci].current;
          if (bilanciTabRef)
            tempContainer.current.appendChild(bilanciTabRef.cloneNode(true));
          setCurrentIndexBilanci((prevIndex) => prevIndex + 1); // Move to next bilanci sub-tab

          // Move to next main tab once all bilanci sub-tabs are processed
          if (currentIndexBilanci === bilanciRefs.length - 1) {
            setCurrentIndexBilanci(0); // Reset for future runs
            setCurrentIndex((prevIndex) => prevIndex + 1);
          }
        } else {
          // Process other tabs (tabContentRefs) normally
          const tabRef = tabContentRefs[currentTabToProcess].current;
          if (tabRef) {
            tempContainer.current.appendChild(tabRef.cloneNode(true));
          }

          // Move to the next tab after processing
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      } else if (generatingPDF && currentIndex === tabsToInclude.length) {
        // Generate the PDF after processing the specified tabs
        const options = {
          margin: 0,
          filename: `${state.data.report.companySummary.companyNumber}_Completa.pdf`,
          html2canvas: {
            scale: 2,
          },
          jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        };
        html2pdf()
          .set(options)
          .from(tempContainer.current)
          .save()
          .finally(() => {
            setGeneratingPDF(false); // Reset generation flag
            tempContainer.current.innerHTML = ""; // Clear the temporary container
          });
      }
    } else {
      if (currentIndex === 0) {
        const tabRef = tabContentRefs[0].current;
        if (tabRef) tempContainer.current.appendChild(tabRef.cloneNode(true));
      }
      const tabsToInclude = [1, 2, 3]; // Only include the selected tabs
      const currentTabToProcess = tabsToInclude[currentIndex]; // Determine which tab to process

      if (generatingPDF && currentIndex < tabsToInclude.length) {
        // Append the content of the specified tab to the temp container
        const tabRef = tabContentRefs[currentTabToProcess].current;

        if (tabRef) tempContainer.current.appendChild(tabRef.cloneNode(true));

        // Move to the next tab in the selected tabs list
        setCurrentIndex((prevIndex) => prevIndex + 2);
      } else if (generatingPDF) {
        // Generate the PDF after processing the specified tabs
        const options = {
          margin: 0,
          filename: `${state.data.report.companySummary.companyNumber}_Semplificata.pdf`,
          html2canvas: { scale: 2 },
          jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
        };
        html2pdf()
          .set(options)
          .from(tempContainer.current)
          .save()
          .finally(() => {
            setGeneratingPDF(false); // Reset generation flag
            tempContainer.current.innerHTML = ""; // Clear the temporary container
          });
      }
    }
  }, [currentIndex, generatingPDF, printAllTabs, currentIndexBilanci]);
  //Modale con scelta di stampa, le if per il tab bilanci, css spazio pagina pdf.

  return (
    <React.Fragment>
      {refresh ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Helmet title="Visure Details" />
          <div ref={tabContentRefs[0]} style={{ padding: "20px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                gap: 4,
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={4}
              >
                <Box display="flex" gap={4}>
                  <img
                    src={`/static/img/flags/${flag.toLowerCase()}.png`}
                    height="32"
                    width="32"
                    alt={state.data.report.companySummary.country}
                    title="Italia"
                  />
                  <Typography variant="h3" display="inline">
                    {state.data.report.companySummary.businessName}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Typography>
                  {state.data.report.companySummary.companyNumber}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setIsModalOpen(true)}
                >
                  <Download />
                </Button>
              </Box>
            </Box>
            <Dialog
              open={isModalOpen}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#1B2635" : "#F7F9FC",
                  },
                },
              }}
            >
              <DialogTitle>Stampa PDF</DialogTitle>
              <DialogContent>
                <Box
                  display="flex"
                  alignItems="start"
                  flexDirection="column"
                  gap="8px"
                >
                  <Button
                    onClick={() => generatePDF(false)}
                    fullWidth
                    variant="contained"
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    {generatingPDF && printAllTabs === false ? (
                      <CircularProgress color="secondary" size="20px" />
                    ) : (
                      <Download />
                    )}
                    <Typography sx={{ ml: "4px" }}>
                      Stampa Semplificata
                    </Typography>
                  </Button>
                  <Button
                    onClick={() => generatePDF(true)}
                    fullWidth
                    variant="contained"
                    sx={{ display: "flex", justifyContent: "start" }}
                  >
                    {generatingPDF && printAllTabs === true ? (
                      <CircularProgress color="secondary" size="20px" />
                    ) : (
                      <Download />
                    )}
                    <Typography sx={{ ml: "4px" }}>Stampa Completa</Typography>
                  </Button>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setIsModalOpen(false)}
                  color="primary"
                  sx={{ marginRight: "8px" }}
                >
                  Chiudi
                </Button>
              </DialogActions>
            </Dialog>
            <Divider my={4} />
            <SummaryReport />
          </div>
          <Divider my={4} />
          <Box display={generatingPDF && "none"}>
            <Tabs value={valueTabs} onChange={handleChangeTabs}>
              <Tab label="Sintesi" />
              {state.data.report.localFinancialStatements && (
                <Tab label="Bilanci" />
              )}
              <Tab label="Score & Risk" />
            </Tabs>

            <CustomTabPanel
              value={generatingPDF ? currentIndex : valueTabs}
              index={0}
            >
              <div
                ref={tabContentRefs[1]}
                style={{ padding: "20px" }}
                className="tab-content "
              >
                <Sintesi report={state.data.report} />
              </div>
            </CustomTabPanel>
            <CustomTabPanel
              value={generatingPDF ? currentIndex : valueTabs}
              index={1}
            >
              {state.data.report.localFinancialStatements && (
                <div
                  ref={tabContentRefs[2]}
                  style={{ padding: "20px" }}
                  className="tab-content "
                >
                  <Box>
                    <Tabs
                      value={valueTabsBilanci}
                      onChange={handleChangeTabsBilanci}
                    >
                      <Tab label="Riclassificato" />
                      <Tab label="IFRS" />
                      <Tab label="Documenti" />
                    </Tabs>
                    <CustomTabPanel
                      value={
                        generatingPDF ? currentIndexBilanci : valueTabsBilanci
                      }
                      index={0}
                    >
                      <div ref={bilanciRefs[0]} style={{ padding: "20px" }}>
                        <Riclassificato report={state.data.report} />
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={
                        generatingPDF ? currentIndexBilanci : valueTabsBilanci
                      }
                      index={1}
                    >
                      <div ref={bilanciRefs[1]} style={{ padding: "20px" }}>
                        <IFRS report={state.data.report} />
                      </div>
                    </CustomTabPanel>
                    <CustomTabPanel
                      value={
                        generatingPDF ? currentIndexBilanci : valueTabsBilanci
                      }
                      index={2}
                    >
                      <div
                        ref={bilanciRefs[2]}
                        style={{ padding: "20px" }}
                      ></div>
                    </CustomTabPanel>
                  </Box>
                </div>
              )}
            </CustomTabPanel>
            <CustomTabPanel
              value={generatingPDF ? currentIndex : valueTabs}
              index={2}
            >
              <div
                ref={tabContentRefs[3]}
                style={{ padding: "20px" }}
                className="tab-content "
              >
                <Score report={state.data.report} />
              </div>
            </CustomTabPanel>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

export default Visuredetails;
