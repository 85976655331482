import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
    Link,
    Breadcrumbs as MuiBreadcrumbs,
    Card as MuiCard,
    CardContent as MuiCardContent,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

// Data
let id = 0;
function createData(primo, secondo, terzo, quarto, quinto) {
    return { primo, secondo, terzo, quarto, quinto };
}

const rows = [
    createData("Prova1", 159, 6.0, 24, 4.0),
    createData("Prova2", 237, 9.0, 37, 4.3),
    createData("Prova3", 262, 16.0, 24, 6.0),
    createData("Prova4", 305, 3.7, 67, 4.3),
    createData("Prova5", 356, 16.0, 49, 3.9),
];

function MyTableDemo({ headParams, tableData }) {
    return (
        <Card mb={6}>
            <CardContent pb={1}>
                <Typography variant="h6" gutterBottom>
                    My Table
                </Typography>
                <Typography variant="body2" gutterBottom>
                    A simple example with no frills.
                </Typography>
            </CardContent>
            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            {headParams.map((headParam, index) => (
                                <TableCell align='right' key={index}>{headParam}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.map((row) => (
                            <TableRow key={row.id}>
                                {Object.keys(row).map((key) => (
                                    <TableCell key={key} align="right">
                                        {row[key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Card>
    );
}

function MyTable() {
    const headParams = ["primo", "secondo", "terzo", "quarto", "quinto"];

    return (
        <React.Fragment>
            <Helmet title="My Table" />
            <Typography variant="h3" gutterBottom display="inline">
                My Table
            </Typography>

            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                    Dashboard
                </Link>
                <Link component={NavLink} to="/">
                    Tables
                </Link>
                <Typography>MyTable</Typography>
            </Breadcrumbs>

            <Divider my={6} />
            <MyTableDemo headParams={headParams} tableData={rows} />
        </React.Fragment>
    );
}

export default MyTable;
