import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRole } from "../redux/slices/role";
import { rl } from "../constants";

const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signIn = async (username, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    };
    const response = await fetch(
      "https://devops.mobilityexpress.it/api/authentication",
      requestOptions
    );
    const responseData = await response.json();

    if (responseData.status === 1) {
      // Login successful
      sessionStorage.setItem("token", responseData.data);
      sessionStorage.setItem("role", responseData.rl);
      sessionStorage.setItem("nome", responseData.nome);
      sessionStorage.setItem("cognome", responseData.cognome);
      sessionStorage.setItem("agenzia", responseData.agenzia);
      sessionStorage.setItem("nomeAvatar", responseData.nomeAvatar);

      const rlRequestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: responseData.data }),
      };

      try {
        const response = await fetch(
          "https://devops.mobilityexpress.it/api/getRL",
          rlRequestOptions
        );
        const responseData = await response.json();
        if (responseData.data) {
          dispatch(setRole(rl[responseData.data]));
        }
      } catch (error) {
        console.error("Error fetching rl:", error);
      }

      return true;
    } else {
      // Login failed
      return false;
    }
  };

  const signOut = async () => {
    const token = sessionStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    };
    await fetch("https://devops.mobilityexpress.it/api/logout", requestOptions);

    sessionStorage.clear();
    navigate("/auth/sign-in");
  };

  async function getData(data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, data: data }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/getData",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      switch (data.action) {
        case "menu":
          return responseData;
        case "usersList":
        case "provincie":
        case "comuni":
        case "cap":
        case "tipologiacontatti":
        case "rcAutoGetFilter":
        case "rcAutoGetFilterValue":
        case "getPreviewInfo":
          return responseData;
        default:
          return undefined;
      }
    } catch (error) {
      return error;
    }
  }

  async function setData(action, data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, action, data }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/setData",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  }

  async function getUserInfo(iduser) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token }),
      };

      if (iduser) {
        requestOptions.body = JSON.stringify({ token, iduser: iduser });
      }
      const response = await fetch(
        "https://devops.mobilityexpress.it/api/userGetInfo",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  }

  const getRCAutoDetails = async (idTarga) => {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token,
          data: {
            idUIDTarga: idTarga,
          },
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/rcAutoGetDetails",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  };

  async function sendAnagraficaData(idUser, data) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, idUser, data }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/userSetInfo",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  }

  async function getRcData(rowsPages, pageNumbers, idUidFlotta, filterData) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token,
          rowsPages,
          pageNumbers,
          idUidFlotta,
          ...filterData,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/rcAutoGetList",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  }

  async function rcAutoNewUpdate(data, action) {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, data, action }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/rcAutoNewUpdate",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  }

  const getFleetData = async (rowsPages, pageNumbers, nomeFlotta = "") => {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, rowsPages, pageNumbers, nomeFlotta }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/flotteGetList",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  };

  const flotteNewUpdate = async (nomeFlotta, numeroTarghe, data) => {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, nomeFlotta, numeroTarghe, data }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/flotteNewUpdate",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  };

  const flotteDownload = async (idUidFlotta) => {
    const token = sessionStorage.getItem("token");

    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token, idUidFlotta }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/flotteDownload",
        requestOptions
      );
      const responseData = await response.json();

      if (responseData.status === -1) {
        sessionStorage.clear();
        navigate("/auth/sign-in");
      }

      return responseData;
    } catch (error) {
      return error;
    }
  };

  return {
    signIn,
    signOut,
    getData,
    getUserInfo,
    setData,
    sendAnagraficaData,
    getRcData,
    getRCAutoDetails,
    rcAutoNewUpdate,
    getFleetData,
    flotteNewUpdate,
    flotteDownload,
  };
};

export default useAuth;
