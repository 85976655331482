import React from "react";
import styled from "@emotion/styled";

import { Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";

import Default from "../dashboards/Default";
import SignIn from "../auth/SignIn";
import { useNavigate } from "react-router-dom";

const Alert = styled(MuiAlert)(spacing);

function ProtectedPage() {
  const navigate = useNavigate();
  if(sessionStorage.getItem('token') != null){

    return (
      <React.Fragment>
        <Alert mb={4} severity="info">
          This page is only visible by authenticated users.
        </Alert>
  
        <Default />
      </React.Fragment>
    );
  } else {
    navigate('/auth/sign-in')
    return null;
  } 
  
}

export default ProtectedPage;
