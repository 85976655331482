export const updateStateFromResponse = (response, setFullAddress, setData) => {
  const hasNonEmptyField = Object.values(response.indirizzo).some(
    (field) => field !== ""
  );

  if (hasNonEmptyField) {
    const indirizzo = response.indirizzo;
    setFullAddress(
      `${indirizzo.indirizzoVia} ${indirizzo.indirizzo} ${indirizzo.indirizzoCivico}, ${indirizzo.indirizzoCAP}, ${indirizzo.indirizzoComune} ${indirizzo.indirizzoProvincia}`
    );
  }

  setData((prevData) => ({
    ...prevData,
    // Update the address fields
    indirizzoVia: response.indirizzo.indirizzoVia || prevData.indirizzoVia,
    indirizzo: response.indirizzo.indirizzo || prevData.indirizzo,
    indirizzoCivico:
      response.indirizzo.indirizzoCivico || prevData.indirizzoCivico,
    indirizzoCAP: response.indirizzo.indirizzoCAP || prevData.indirizzoCAP,
    indirizzoComune:
      response.indirizzo.indirizzoComune || prevData.indirizzoComune,
    indirizzoProvincia:
      response.indirizzo.indirizzoProvincia || prevData.indirizzoProvincia,

    // Update contact fields
    email: response.contatti.email || prevData.email,
    telefono: response.contatti.telefono || prevData.telefono,
    cellulare: response.contatti.cellulare || prevData.cellulare,

    // Update other information fields with default values
    titoloStudio:
      response.altreInformazioni.titoloStudio.valoreDefault ||
      prevData.titoloStudio,
    professione:
      response.altreInformazioni.professione.valoreDefault ||
      prevData.professione,
    statoCivile:
      response.altreInformazioni.statoCivile.valoreDefault ||
      prevData.statoCivile,
    tipoGuida:
      response.altreInformazioni.tipoGuida.valoreDefault || prevData.tipoGuida,
    antifurto:
      response.altreInformazioni.antifurto.valoreDefault || prevData.antifurto,
    ricovero:
      response.altreInformazioni.ricovero.valoreDefault || prevData.ricovero,
    usoVeicolo:
      response.altreInformazioni.usoVeicolo.valoreDefault ||
      prevData.usoVeicolo,
    figli: response.altreInformazioni.figli.valoreDefault || prevData.figli,

    // Update idUID
    idUID: response.idUID || prevData.idUID,
  }));
};

export const mapAddressComponentsToState = (addressComponents, setData) => {
  const addressData = {
    indirizzoVia: "",
    indirizzo: "",
    indirizzoCivico: "",
    indirizzoCAP: "",
    indirizzoComune: "",
    indirizzoProvincia: "",
  };

  addressComponents.forEach((component) => {
    const types = component.types;
    const shortName = component.short_name;

    if (types.includes("street_number")) {
      addressData.indirizzoCivico = shortName;
    } else if (types.includes("route")) {
      const routeParts = shortName.split(" ");
      addressData.indirizzoVia = routeParts[0]; // First part of the route (Via)
      addressData.indirizzo = routeParts.slice(1).join(" "); // The rest of the route
    } else if (types.includes("locality")) {
      addressData.indirizzoComune = shortName;
    } else if (types.includes("administrative_area_level_2")) {
      addressData.indirizzoProvincia = shortName;
    } else if (types.includes("postal_code")) {
      addressData.indirizzoCAP = shortName;
    }
  });

  setData((prev) => ({
    ...prev,
    ...addressData,
  }));
};

export const validateTarga = (targa) => {
  const targaRegex =
    /^(?:[A-Z]{2}\d{3}[A-Z]{2}|[A-Z]{2}\d{5}|[A-Z]{3}\d{5}|[A-Z]{2}\d{6})$/;
  return targaRegex.test(targa);
};
