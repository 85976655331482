import React from "react";
import { TextField, Box, Typography } from "@mui/material";

function NameSection({ theme, name, handleChangeName, fileName }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography variant="body" color={theme.palette.success.main} mr={4}>
        {fileName}
      </Typography>
      <TextField
        name="nome"
        type="text"
        label="Nome flotta"
        sx={{
          maxWidth: "400px",
          flex: "1",
          [theme.breakpoints.down("sm")]: {
            // width: "200px",
          },
        }}
        size="small"
        value={name}
        onChange={handleChangeName}
        inputProps={{
          maxLength: 45, // Limit the input to 45 characters
        }}
      />
    </Box>
  );
}

export default NameSection;
