import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Divider as MuiDivider,
  Tooltip,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import Tabella from "./Tabella";
import { Add, Update } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Charts from "./Charts";
import NuovoServizio from "./modale/Nuovo Servizio e Bot/NuovoServizio";

const Divider = styled(MuiDivider)(spacing);

function Services() {
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [table, setTable] = useState([]);
  const [graph, setGraph] = useState([]);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function servicesGetList() {
    const token = sessionStorage.getItem("token");
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token,
        }),
      };

      const response = await fetch(
        "https://devops.mobilityexpress.it/api/servicesGetList",
        requestOptions
      );

      const responseData = await response.json();
      if (responseData.status === -1) {
        navigate("/auth/sign-in");
      }
      if (responseData.status === 0) {
        navigate("/private");
      }
      const dataTable = responseData.data.dataTable;
      const dataGraph = responseData.data.dataGraph;
      setTable(dataTable);
      setGraph(dataGraph);
      setLoading(false);
    } catch (error) {
      return error;
    }
  }

  const handleClickRefresh = () => {
    if (loading) {
      return;
    } else {
      setLoading(true);
      servicesGetList();
    }
  };

  const handleClickModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    servicesGetList().finally(() => setLoadingPage(false));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Micro Servizi" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h3" display="inline">
          Micro Servizi
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 4,
            alignItems: "center",
          }}
        >
          <Tooltip title="Aggiorna">
            <Button variant="contained" onClick={handleClickRefresh}>
              {loading ? (
                <CircularProgress sx={{ color: "white" }} size={22} />
              ) : (
                <Update />
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Crea servizio/bot">
            <Button variant="contained" onClick={handleClickModal}>
              <Add />
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Divider my={4} />

      {loadingPage ? (
        <Box
          sx={{
            width: "calc(100vw - 40px)",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Charts graph={graph} dataTabella={table} />
          </Grid>
          <Grid item xs={12}>
            <Tabella table={table} />
          </Grid>
        </Grid>
      )}
      <NuovoServizio open={isModalOpen} setOpen={setIsModalOpen} />
    </React.Fragment>
  );
}

export default Services;
