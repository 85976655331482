import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import MyLogoLight from "../../vendor/ME_Light.png";
import MyLogoDark from "../../vendor/ME_Dark.png";
import SignUpComponent from "../../components/auth/SignUp";

const Brand = styled.img`
  fill: ${(props) => props.theme.palette.primary.main};
  display: block;
  margin: 0 auto;
  width: 256px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignUp() {
  const navigate = useNavigate();

  useEffect(() => {
    const isUserLoggedIn = sessionStorage.getItem('token') != null;
    if (isUserLoggedIn) {
      navigate("/private");
    }
  }, [navigate]);
  return (
    <React.Fragment>
      <Wrapper>
      <Brand src={MyLogoLight} />
        <Helmet title="Sign Up" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Get started
        </Typography>
        

        <SignUpComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignUp;
