import React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Divider,
  Popover as MuiPopover,
  Typography,
  SvgIcon,
  Button,
} from "@mui/material";
import { Lock } from "react-feather";
import { useNavigate } from "react-router-dom";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    padding: 16px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const ContentBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ButtonBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 16px;
`;

const CenteredBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const SectionTitle = styled(Typography)`
  margin-top: 8px;
  font-weight: bold;
`;

function PopoverTarga({
  data,
  idUID,
  canViewTargaData,
  isTargaPopupOpen,
  handleCloseTargaPopup,
  targaRef,
}) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(`/pages/rcautodetails/${idUID}`);
  };

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      anchorEl={targaRef}
      onClose={handleCloseTargaPopup}
      open={isTargaPopupOpen}
    >
      {canViewTargaData ? (
        <ContentBox>
          <FlexBox>
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              {data.targa}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              {data.marca} {data.modello}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: "12px" }}
            >
              {data.allestimento}
            </Typography>

            <Typography
              variant="body2"
              sx={{ marginTop: "8px", fontSize: "12px" }}
            >
              <strong>Acquistata il:</strong> {data.dataAcquisto}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              <strong>Immatricolata il:</strong> {data.dataImmatricolazione}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: "8px", fontSize: "12px" }}
            >
              {data.alimentazione} - {data.cilindrata} cc - {data.KW} kW
            </Typography>

            <Divider sx={{ marginY: 2 }} />

            <SectionTitle variant="h6" gutterBottom>
              Ultima copertura
            </SectionTitle>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              {data.assicurazioneProvenienza}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              {data.dataInizioCopertura} - {data.dataFineCopertura}
            </Typography>
            <Typography
              variant="body2"
              sx={{ marginTop: "8px", fontSize: "12px" }}
            >
              Classe di merito: {data.classeMerito}
            </Typography>
          </FlexBox>
          <ButtonBox>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigation}
            >
              Visualizza tutto
            </Button>
          </ButtonBox>
        </ContentBox>
      ) : (
        <CenteredBox>
          <SvgIcon fontSize="medium">
            <Lock />
          </SvgIcon>
          <Typography
            variant="body2"
            sx={{ textAlign: "center", marginTop: "8px" }}
          >
            Lei non ha accesso ai dati di questa targa
          </Typography>
        </CenteredBox>
      )}
    </Popover>
  );
}

export default PopoverTarga;
