// DynamicForm.js
import React, { useState } from "react";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  Card as MuiCard,
  CardHeader,
  FormControl,
  CardContent,
} from "@mui/material";

const Card = styled(MuiCard)(spacing);

function DynamicForm({ formConfig }) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validazione in tempo reale
    const field = formConfig.fields.find((f) => f.name === name);
    if (field && field.validation && field.validation.regex) {
      const regex = new RegExp(field.validation.regex);
      if (!regex.test(value)) {
        setErrors((prev) => ({
          ...prev,
          [name]: field.validation.errorMessage,
        }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let valid = true;
    let newErrors = {};

    formConfig.fields.forEach((field) => {
      if (field.validation.required && !formData[field.name]) {
        valid = false;
        newErrors[field.name] = `${field.label} è obbligatorio.`;
      } else if (field.validation.regex) {
        const regex = new RegExp(field.validation.regex);
        if (!regex.test(formData[field.name])) {
          valid = false;
          newErrors[field.name] = field.validation.errorMessage;
        }
      }
    });

    setErrors(newErrors);

    if (valid) {
      // Invia i dati al backend o gestisci come necessario
      console.log("Dati del form:", formData);
      // Reset del form se necessario
      // setFormData({});
    }
  };

  return (
    <Card mb={6}>
      <CardHeader title={formConfig.formTitle} />
      <CardContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ width: "100%" }}>
          <Grid container spacing={4}>
            {formConfig.fields.map((field, index) => (
              <Grid item key={index} xs={12} sm={field.grid.sm || 12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={field.label}
                  name={field.name}
                  type={field.type === "textarea" ? "text" : field.type}
                  placeholder={field.placeholder}
                  required={field.validation.required}
                  InputProps={{
                    readOnly: !field.editable,
                  }}
                  inputProps={{
                    minLength: field.validation.minLength,
                    pattern: field.validation.regex,
                    min: field.validation.min,
                    max: field.validation.max,
                  }}
                  multiline={field.type === "textarea"}
                  rows={field.type === "textarea" ? 4 : 1}
                  value={formData[field.name] || ""}
                  onChange={handleChange}
                  error={Boolean(errors[field.name])}
                  helperText={errors[field.name]}
                />
              </Grid>
            ))}
          </Grid>
          <Box mt={4}>
            <Button variant="contained" color="primary" type="submit">
              {formConfig.submitButtonText}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
//
export default DynamicForm;
