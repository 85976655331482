import React from "react";
import { Box, TextField, Typography, IconButton, Fade } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const Contatti = ({
  data,
  isContattiVisible,
  toggleContattiVisibility,
  maxWidth,
  handleChange,
}) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: isContattiVisible ? "16px" : "0",
        }}
      >
        <Typography variant="h6">Contatti</Typography>
        <IconButton onClick={toggleContattiVisibility}>
          <ExpandMore
            sx={{
              transform: isContattiVisible ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          />
        </IconButton>
      </Box>
      {isContattiVisible && (
        <Fade in={isContattiVisible}>
          <Box
            sx={{
              display: "flex",
              flexDirection: maxWidth === "xs" ? "column" : "row",
            }}
          >
            <TextField
              name="email"
              size="small"
              label="Email"
              value={data.email}
              onChange={handleChange}
              sx={{
                marginRight: maxWidth === "xs" ? "0" : "16px",
                marginBottom: maxWidth === "xs" ? "16px" : "0",
              }}
            />
            <TextField
              name="telefono"
              size="small"
              label="Telefono"
              value={data.telefono}
              onChange={handleChange}
              sx={{
                marginRight: maxWidth === "xs" ? "0" : "16px",
                marginBottom: maxWidth === "xs" ? "16px" : "0",
              }}
            />
            <TextField
              size="small"
              label="Cellulare"
              name="cellulare"
              value={data.small}
              onChange={handleChange}
            />
          </Box>
        </Fade>
      )}
    </>
  );
};

export default Contatti;
