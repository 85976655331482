import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Box,
    Typography
} from '@mui/material';
import { Paper } from '../style';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@mui/icons-material';

const DynamicTable = ({ name, dataset }) => {
    const pageSize = 3;
    const [currentPage, setCurrentPage] = useState(0);

    const startIndex = currentPage * pageSize;
    const endIndex = startIndex + pageSize;
    const currentRows = dataset.slice(startIndex, endIndex);

    const headers = dataset.length > 0 ? Object.keys(dataset[0]) : [];
    const totalPages = Math.ceil(dataset.length / pageSize);

    const handleNextPage = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setCurrentPage((prevPage) => prevPage - 1);
        }
    };

    return (
        <Box>
            <TableContainer component={Paper}>
                <Table aria-label={name} sx={{ border: "2px solid gray" }}>
                    <TableHead>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell key={header} sx={{ fontWeight: 'bold' }}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {currentRows.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                {headers.map((header, colIndex) => (
                                    <TableCell key={colIndex}>
                                        <Box>
                                            {Array.isArray(row[header]) ? (
                                                // Caso in cui l'elemento è un array
                                                row[header].length === 1 ? (
                                                    <Typography>{row[header][0]}</Typography>
                                                ) : row[header].length === 2 ? (
                                                    <>
                                                        <Typography>{row[header][0]}</Typography>
                                                        <Typography variant="body2" sx={{ color: 'gray' }}>
                                                            {row[header][1]}
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    row[header].map((value, idx) => (
                                                        <Typography key={idx}>{value}</Typography>
                                                    ))
                                                )
                                            ) : (
                                                <Typography>{row[header]}</Typography>
                                            )}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button
                    onClick={handlePrevPage}
                    disabled={currentPage === 0}
                >
                    <ArrowLeftOutlined />
                </Button>
                <Typography>
                    Pagina {currentPage + 1} di {totalPages}
                </Typography>
                <Button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages - 1}
                >
                    <ArrowRightOutlined />
                </Button>
            </Box>
        </Box>
    );
};

export default DynamicTable;
