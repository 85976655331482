const ITEM_HEIGHT = 36;
const MOBILE_ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MENU_ITEMS = 5;
export const CustomSelectMenuProps = {
  PaperProps: {
    sx: {
      maxHeight: {
        xs: MOBILE_ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
        sm: ITEM_HEIGHT * MENU_ITEMS + ITEM_PADDING_TOP,
      },
    },
  },
};

export const generalErrorMessage = "Qualcosa e andato storto.";

export const savedDataMessage = "I dati sono stati salvati.";

export const rebootMessage = "Azione eseguita correttamente.";
