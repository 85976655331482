import React from "react";
import { Box, Button, Typography, TextField, Card, Stack } from "@mui/material";
import CustomSelect from "../../../components/CustomSelect";

function Options({
  handleChangeOptions,
  optionsData,
  theme,
  sheetNames,
  handleExtractData,
}) {
  return (
    <Card>
      <Typography variant="h6" mb={2}>
        Opzioni
      </Typography>
      <Stack direction="row" flexWrap={"wrap"}>
        <CustomSelect
          name="page"
          label="Foglio"
          size="small"
          onChange={handleChangeOptions}
          value={optionsData.page}
          options={sheetNames}
          sx={{
            width: "200px",
            minWidth: "200px",
            margin: "8px 16px 8px 0",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "0",
              margin: "8px 0",
              width: "100%",
            },
          }}
        />
        <TextField
          size="small"
          name="column"
          label="Colona"
          type="number"
          onChange={handleChangeOptions}
          value={optionsData.column}
          inputProps={{
            min: 1,
          }}
          sx={{
            margin: "8px 16px 8px 0",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "0",
              margin: "8px 0",
              width: "100%",
            },
          }}
        />
        <TextField
          size="small"
          name="row"
          label="Riga"
          type="number"
          onChange={handleChangeOptions}
          value={optionsData.row}
          inputProps={{
            min: 1,
          }}
          sx={{
            margin: "8px 16px 8px 0",
            [theme.breakpoints.down("sm")]: {
              marginBottom: "0",
              margin: "8px 0",
              width: "100%",
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("sm")]: {
              justifyContent: "end",
              width: "100%",
            },
          }}
        >
          <Button
            variant="contained"
            disabled={
              !optionsData.page || !optionsData.column || !optionsData.row
            }
            sx={{
              margin: "8px 0",
              [theme.breakpoints.down("sm")]: {
                margin: "8px 0",
              },
            }}
            onClick={handleExtractData}
          >
            Verifica
          </Button>
        </Box>
      </Stack>
    </Card>
  );
}

export default Options;
