import React from "react";
import { Snackbar, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";

const CustomSnackbar = ({
  snackbarOpen,
  snackbarMessage,
  snackbarSeverity = "success",
  onClose,
  autoHideDuration = 4000,
  transitionDuration = { enter: 500, exit: 1000 },
}) => {
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={onClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      open={snackbarOpen}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      TransitionComponent={Slide}
      transitionDuration={transitionDuration}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
      action={action}
      key={snackbarMessage}
    >
      <Alert
        onClose={onClose}
        severity={snackbarSeverity}
        sx={{ width: "100%" }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
