export const rowsRiclassificato = [
  {
    operatingRevenues: "Ricavi Operativi",
    changeInWorkInProgress: "Variazioni delle Rimanenza dei Prodotti",
    totalOtherIncomeAndRevenues: "Altri Ricavi e Proventi",
    totalValueOfProduction: "Total Valore della produzione",
    totalCostOfProduction: "Totale Costi della Produzione",
    purchaseOfGoods: "Costo per Acquisti",
    costOfServices: "Costo per Servizi",
    useOfThirdPartyAssets: "Costo per Godimento di Beni di Terzi",
    totalPayrollAndRelatedCosts: "Costo del Personale",
    otherOperatingExpenses: "Oneri diversi di Gestione",
    ebitda: "Margine Operativo Lordo (EBITDA)",
    totalAmortisationDepreciationAndWriteDowns: "Ammortamenti e Svalutazioni",
    ebit: "Risultato operativo (EBIT)",
    totalFinancialIncomeAndExpense: "Proventi e Oneri Finanziari",
    totalValueAdjustmentsToFinancialAssetsAndLiabilities:
      "Rettifiche di Valore di attività/passitività Finanziarie",
    preTaxResult: "Risultato Prima delle Imposte",
    totalTaxesOnTheIncomeForTheYear: "Imposte sul reddito d'esercizio",
    profitOrLossForTheYear: "Utile (Perdita) dell'esercizio",
    cashFlowPL: "Flusso di Cassa",
  },
  {
    receivablesDueFromShareholders: "Crediti verso soci",
    intangibleFixedAssets: "Immobilizzazioni immateriali",
    tangibleFixedAssets: "Immobilizzazioni materiali",
    financialFixedAssets: "Immobilizzazioni finanziarie",
    totalFixedAssets: "Totale immobilizzazioni",
    totalInventories: "Totale rimanenze",
    totalReceivables: "Totale crediti",
    debtorsDueWithin1Year: "Crediti entro 12 mesi", // Right
    dueFromSuppliersWithin1Year: "di cui verso clienti nei 12 mesi", // Right
    currentFinancialAssets:
      "Attività finanziarie che non costituiscono immobilizzazioni",
    liquidAssets: "Totale disponibilità liquide",
    totalCurrentAssets: "Totale attivo circolante",
    accruedIncomeAndPrepayments: "Ratei e risconti attivi",
    totalAssets: "Totale attivo",
  },
  {
    shareholdersEquity: "Patrimonio netto",
    shareCapital: "Capitale Sociale",
    otherReserves: "Altre riserve",
    netProfitOrLossForTheYear: "Utile (Perdita) dell'esercizio",
    provisionForRisksAndCharges: "Fondi per rischi ed oneri",
    provisionForSeveranceIndemnity: "Fondo TFR",
    totalPayables: "Totale debiti",
    dueWithin1Year: "Debiti entro 12 mesi",
    dueToSuppliersWithin1Year: "di cui verso fornitori",
    dueToBankWithin1Year: "di cui verso banche",
    dueAfter1Year: "Debiti oltre i 12 mesi",
    dueToSuppliersAfter1Year: "di cui verso fornitori",
    dueToBankAfter1Year: "di cui verso banche",
    otherPayables: "Altri debiti",
    accruedExpensesAndPrepayments: "Ratei e risconti passivi",
    totalLiabilities: "Totale passivo",
  },
  {
    changeInRevenuesPercentage: "Variazione dei ricavi (%)",
    changeInTotalValueOfProductionPercentage:
      "Variazione valore della produzione (%)",
    changeInTotalAssetsPercentage: "Variazione dell'attivo (%)",
    changeInShareholdersEquityPercentage: "Variazione del patrimonio netto (%)",
    returnOnSalesPercentage: "R.O.S. (Return On Sales) (%)",
    returnOnInvestmentPercentage: "R.O.I. (Return On Investment) (%)",
    returnOnEquityPercentage: "R.O.E. (Return on equity) (%)",
    currentRatio: "Indice di disponibilità",
    acidTest: "Indice di liquidità immediata (Acid test)",
    debtorDays: "Tempo medio di incasso (gg)",
    creditorDays: "Tempo medio di pagamento (gg)",
    pfn: "P.F.N. (Posizione Finanziaria Netta)",
  },
];

export const rowsIFRS = [
  {
    revenue: "Ricavi",
    operatingCosts: "Costi operativi",
    operatingProfit: "Reddito operativo",
    wagesAndSalaries: "Salari e stipendi",
    depreciation: "Ammortamenti delle immobilizzazioni materiali",
    amortisation: "Ammortamenti delle immobilizzazioni immateriali",
    financialIncome: "Risultato della gestione finanziaria",
    financialExpenses: "Oneri finanziari",
    profitBeforeTax: "Utile ante imposte",
    tax: "Imposte",
    profitAfterTax: "Utile netto",
    otherAppropriations: "Altri stanziamenti",
    retainedProfit: "Utile non distribuito",
  },
  {
    otherTangibleAssets: "Altre immobilizzazioni materiali",
    totalTangibleAssets: "Totale immobilizzazioni materiali",
    otherIntangibleAssets: "Altre immobilizzazioni immateriali",
    totalIntangibleAssets: "Totale immobilizzazioni immateriali",
    loansToGroup: "Crediti intercompany",
    miscellaneousFixedAssets: "Altre immobilizzazioni",
    totalOtherFixedAssets: "Totale altre immobilizzazioni",
    totalFixedAssets: "Totale immobilizzazioni",
    workInProgress: "Rimanenze per lavori in corso",
    otherInventories: "Altre rimanenze",
    totalInventories: "Totale rimanenze",
    tradeReceivables: "Crediti verso clienti entro 12 mesi",
    groupReceivables: "Crediti infragruppo",
    receivablesDueAfter1Year: "Crediti a medio/lungo termine",
    miscellaneousReceivables: "Altri crediti",
    totalReceivables: "Totale crediti",
    cash: "Disponibilità liquide",
    otherCurrentAssets: "Altre attività correnti",
    totalCurrentAssets: "Totale attivo circolante",
    totalAssets: "Totale attivo",
    tradePayables: "Debiti verso fornitori entro 12 mesi",
    bankLiabilities: "Debito verso banche entro 12 mesi",
    otherLoansOrFinance: "Altri debiti finanziari",
    groupPayables: "Debiti infragruppo",
    miscellaneousLiabilities: "Altri debiti entro 12 mesi",
    totalCurrentLiabilities: "Totale passivo corrente",
    tradePayablesDueAfter1Year: "Debiti verso fornitori oltre 12 mesi",
    bankLiabilitiesDueAfter1Year: "Debiti verso banche oltre 12 mesi",
    otherLoansOrFinanceDueAfter1Year: "Altri debiti finanziari oltre 12 mesi",
    groupPayablesDueAfter1Year: "Debiti infragruppo oltre i 12 mesi",
    miscellaneousLiabilitiesDueAfter1Year: "Altri debiti oltre 12 mesi",
    totalLongTermLiabilities: "Totale passivo a medio/lungo termine",
    totalLiabilities: "Totale passivo",
    calledUpShareCapital: "Capitale Sociale",
    sharePremium: "Riserva da sovrapprezzo azioni",
    revenueReserves: "Riserve di utili",
    otherReserves: "Altre riserve",
    totalShareholdersEquity: "Patrimonio netto",
  },
  {
    workingCapital: "Capitale circolante netto",
    netWorth: "Valore netto",
  },
  {
    preTaxProfitMargin: "Utile lordo (%)",
    returnOnCapitalEmployed: "ROCE (Return on capital employed) (%)",
    returnOnTotalAssetsEmployed: "ROA (Return on total assets employed) (%)",
    returnOnNetAssetsEmployed: "RONA (Return on net assets employed) (%)",
    salesOrNetWorkingCapital: "Valore produzione / Capitale circolante netto",

    stockTurnoverRatio: "Turnover del magazzino (%)",
    debtorDays: "Tempo medio di incasso (gg)",
    creditorDays: "Tempo medio di pagamento (gg)",

    currentRatio: "Indice di disponibilità",
    liquidityRatioOrAcidTest: "Indice di liquidità",
    currentDebtRatio: "Indice di indebitamento a breve termine",

    gearing: "Indice indebitamento a medio lungo termine",
    equityInPercentage: "Indice di indipendenza finanziaria",
    totalDebtRatio: "Indice di indebitamento",
  },
];
