import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { CacheProvider } from "@emotion/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import createEmotionCache from "./utils/createEmotionCache";

import { AuthProvider } from "./contexts/JWTContext";
import { setRole } from "./redux/slices/role";
import { useDispatch } from "react-redux";
import { rl } from "./constants";
import CustomSnackbar from "./pages/components/CustomSbackbar";
import { useSelector } from "react-redux";
import { hideSnackbar } from "./redux/slices/snackbar";

const clientSideEmotionCache = createEmotionCache();
const token = sessionStorage.getItem("token");

function App({ emotionCache = clientSideEmotionCache }) {
  const WEBSOCKET_URL = "ws://devops.mobilityexpress.it:7777";
  const dispatch = useDispatch();
  // const messages = useSelector((state) => state.websocket.messages);
  // const status = useSelector((state) => state.websocket.status);
  // const error = useSelector((state) => state.websocket.error);
  // console.log(messages, status);
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const location = useLocation();
  const isNotAuthenticated = location.pathname.includes("auth");
  const { snackbarOpen, snackbarMessage, snackbarSeverity } = useSelector(
    (state) => state.snackbar
  );

  useEffect(() => {
    dispatch({ type: "websocket/connect", payload: WEBSOCKET_URL });

    return () => {
      dispatch({ type: "websocket/disconnect" });
    };
  }, [dispatch]);

  const GetRl = useCallback(async (token) => {
    if (!token) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token }),
    };
    try {
      const response = await fetch(
        "https://devops.mobilityexpress.it/api/getRL",
        requestOptions
      );
      const responseData = await response.json();
      if (responseData.data) {
        dispatch(setRole(rl[responseData.data]));
      }
    } catch (error) {
      console.error("Error fetching rl:", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      GetRl(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { theme } = useTheme();

  if (sessionStorage.getItem("token") === null && !isNotAuthenticated) {
    navigate("/auth/sign-in");
  }

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <CacheProvider value={emotionCache}>
      <HelmetProvider>
        <Helmet
          titleTemplate="Mobility Express | %s"
          defaultTitle="Mobility Express - Dashboard"
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <AuthProvider>
              <CustomSnackbar
                snackbarOpen={snackbarOpen}
                snackbarMessage={snackbarMessage}
                snackbarSeverity={snackbarSeverity}
                onClose={handleClose}
              />
              {content}
            </AuthProvider>
          </MuiThemeProvider>
        </LocalizationProvider>
      </HelmetProvider>
    </CacheProvider>
  );
}

export default App;
