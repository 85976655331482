import { createSlice } from "@reduxjs/toolkit";

export const sidebarMenuSlice = createSlice({
  name: "sidebarMenu",
  initialState: {
    datiMenu: [],
    loaded: false,
  },
  reducers: {
    setMenuData: (state, action) => {
      state.datiMenu = action.payload;
      state.loaded = true;
    },
  },
});

export const { setMenuData } = sidebarMenuSlice.actions;
export default sidebarMenuSlice.reducer;
